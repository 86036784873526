/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type { PvpController, PvpControllerInterface } from "../PvpController";

const _abi = [
  {
    inputs: [],
    name: "BiomeAlreadySelected",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "key",
        type: "bytes32",
      },
    ],
    name: "EnumerableMapNonexistentKey",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "ErrorHeroIsDead",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
    ],
    name: "ErrorHeroIsNotRegistered",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "biome",
        type: "uint256",
      },
    ],
    name: "ErrorIncorrectBiome",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "heroLevel",
        type: "uint256",
      },
    ],
    name: "ErrorLevelTooLow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotGuildController",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ErrorNotOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorPaused",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "right",
        type: "uint256",
      },
    ],
    name: "GuildActionForbidden",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "NoDominationRequest",
    type: "error",
  },
  {
    inputs: [],
    name: "NotGuildMember",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "PvpFightOpponentNotFound",
    type: "error",
  },
  {
    inputs: [],
    name: "PvpHeroHasInitializedFight",
    type: "error",
  },
  {
    inputs: [],
    name: "PvpHeroNotRegistered",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "Staked",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "TooHighValue",
    type: "error",
  },
  {
    inputs: [],
    name: "TooLowGuildLevel",
    type: "error",
  },
  {
    inputs: [],
    name: "UnknownPvpStrategy",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "UserHasRegisteredPvpHeroInBiome",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "week",
        type: "uint256",
      },
    ],
    name: "UserNotAllowedForPvpInCurrentEpoch",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroAddress",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "week",
        type: "uint32",
      },
    ],
    name: "AddBiomeRequest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "week",
        type: "uint32",
      },
    ],
    name: "FirstPvpEpoch",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint48",
        name: "fightId",
        type: "uint48",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "week",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroGuildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "opponentHero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "opponentHeroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "opponentGuildId",
        type: "uint256",
      },
    ],
    name: "PreparePvpFight",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "week",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "PvpHeroAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "week",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "manuallyRemoved",
        type: "bool",
      },
    ],
    name: "PvpHeroRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "adapter",
        type: "address",
      },
    ],
    name: "SetGuildStakingAdapter",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
    ],
    name: "SetMinHeroLevel",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "week",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildBiomeOwnerId",
        type: "uint256",
      },
    ],
    name: "UpdatePvpEpoch",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "pvpStrategy",
        type: "bytes",
      },
      {
        internalType: "uint8",
        name: "maxFights",
        type: "uint8",
      },
    ],
    name: "addPvpHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint32",
        name: "week",
        type: "uint32",
      },
    ],
    name: "getBiomeGuilds",
    outputs: [
      {
        internalType: "uint256[]",
        name: "guildIds",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "getBiomeOwner",
    outputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "getBiomeTax",
    outputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "taxPercent",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCounterFightId",
    outputs: [
      {
        internalType: "uint48",
        name: "",
        type: "uint48",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "blockTimestamp",
        type: "uint256",
      },
    ],
    name: "getCurrentEpochWeek",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "getDominationCounter",
    outputs: [
      {
        internalType: "uint16",
        name: "dominationCounter",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "week",
        type: "uint32",
      },
    ],
    name: "getDominationRequest",
    outputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "index0",
        type: "uint256",
      },
    ],
    name: "getFightDataByIndex",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "fightOpponent",
            type: "address",
          },
          {
            internalType: "enum IPvpController.PvpFightStatus",
            name: "fightStatus",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "health",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "mana",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "countTurns",
            type: "uint8",
          },
        ],
        internalType: "struct IPvpController.PvpFightData",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getFightDataLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "getFreeUsers",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "getGuildPoints",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getMinHeroLevel",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "getPvpStrategy",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "getPvpStrategyKind",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "getStartedEpoch",
    outputs: [
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "week",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserState",
    outputs: [
      {
        components: [
          {
            internalType: "uint8",
            name: "biome",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "activeFightIndex1",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "numHeroesStaked",
            type: "uint32",
          },
          {
            internalType: "uint64",
            name: "guildId",
            type: "uint64",
          },
          {
            internalType: "uint8",
            name: "countFights",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "maxFights",
            type: "uint8",
          },
          {
            internalType: "uint48",
            name: "fightId",
            type: "uint48",
          },
        ],
        internalType: "struct IPvpController.PvpUserState",
        name: "userState",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "week",
        type: "uint32",
      },
    ],
    name: "hasPvpHero",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "epoch",
        type: "uint32",
      },
    ],
    name: "isHeroStaked",
    outputs: [
      {
        internalType: "bool",
        name: "staked",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "isHeroStakedCurrently",
    outputs: [
      {
        internalType: "bool",
        name: "staked",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "forwarder",
        type: "address",
      },
    ],
    name: "isTrustedForwarder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "onGuildDeletion",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "ownedBiome",
    outputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "prepareFight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "refreshBiomeTax",
    outputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "taxPercent",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "registeredHero",
    outputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint32",
        name: "epochWeek",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "registeredUsers",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "removePvpHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "selectBiomeForDomination",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "adapter_",
        type: "address",
      },
    ],
    name: "setGuildStakingAdapter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
    ],
    name: "setMinHeroLevel",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "maxCountTurns",
        type: "uint8",
      },
    ],
    name: "startFight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "updateEpoch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class PvpController__factory {
  static readonly abi = _abi;
  static createInterface(): PvpControllerInterface {
    return new Interface(_abi) as PvpControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): PvpController {
    return new Contract(address, _abi, runner) as unknown as PvpController;
  }
}
