import { Injectable } from '@angular/core';
import { HeroController__factory } from '@data/abi';
import { IStatController } from '@data/abi/IStatController';
import { TransactionDataModel } from '@models/transaction-data.model';
import { ErrorService } from '@services/error.service';
import { FeesExtension, ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { RelayService } from '@services/onchain/relay.service';
import { ProviderService } from '@services/provider.service';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { adjustGasLimit } from '@shared/utils';
import { NGXLogger } from 'ngx-logger';
import { catchError, concatMap, from, retry } from 'rxjs';

import CoreAttributesStruct = IStatController.CoreAttributesStruct;


export type HeroCreationData = {
  /// @notice Desired NG_LVL of the hero
  ngLevel: number;
  /// @notice Desired tire of the newly created hero. Allowed values: [1..3]
  tier: number;
  /// @notice Enter to the dungeon after creation
  enter: boolean;
  /// @notice Desired hero name
  heroName: string;
  /// @notice Optional: user account for which the hero is created
  targetUserAccount: string;
  /// @notice Optional: ref-code to be passed to the hero-creation-related event
  refCode: string;
};

@Injectable({
  providedIn: 'root',
})
export class HeroControllerService extends FeesExtension {
  constructor(
    private providerService: ProviderService,
    private errorService: ErrorService,
    private logger: NGXLogger,
    private relayService: RelayService,
  ) {
    super();
  }

  // --- FACTORIES ---

  private createHeroController(chainId: number) {
    return HeroController__factory.connect(
      GET_CORE_ADDRESSES(chainId).heroController,
      this.providerService.getProviderForRead(),
    );
  }

  // --- VIEWS ---

  nameToHero$(name: string, chainId: number) {
    this.logger.trace('nameToHero', name, chainId);
    return from(this.createHeroController(chainId).nameToHero(name)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  maxOpenedNgLevel$(chainId: number) {
    this.logger.trace('maxOpenedNgLevel', chainId);
    return from(this.createHeroController(chainId).maxOpenedNgLevel()).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  maxUserNgLevel$(chainId: number, account: string) {
    return from(this.createHeroController(chainId).maxUserNgLevel(account)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  // --- CALLS ---

  askReinforcement$(signer: string, chainId: number, hero: string, id: number, helper: string, helperId: number) {
    return from(this.createHeroController(chainId).askReinforcement.estimateGas(hero, id, helper, helperId)).pipe(
      concatMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas =>
        this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Ask reinforcement',
            showLoadingScreen: true,
            isNeedUpdateHero: true,
            txPopulated: this.createHeroController(chainId).askReinforcement.populateTransaction(
              hero,
              id,
              helper,
              helperId,
            ),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        ),
      ),
      catchError(this.errorService.onCatchError),
    );
  }

  levelUp$(signer: string, chainId: number, hero: string, id: number, attributes: CoreAttributesStruct) {
    return from(this.createHeroController(chainId).levelUp.estimateGas(hero, id, attributes)).pipe(
      concatMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Level up',
            isNeedUpdateHero: true,
            isNeedUpdateBalances: true,
            txPopulated: this.createHeroController(chainId).levelUp.populateTransaction(hero, id, attributes),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  create$(signer: string, chainId: number, hero: string, name: string, refCode: string, enter = true) {
    this.logger.trace('createHero', hero, name, refCode, enter);
    return from(
      this.createHeroController(chainId).createWithRefCode.estimateGas(hero, name, refCode.toLowerCase(), enter),
    ).pipe(
      concatMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Create hero',
            isNeedUpdateHero: true,
            isNeedUpdateBalances: true,
            txPopulated: this.createHeroController(chainId).createWithRefCode.populateTransaction(
              hero,
              name,
              refCode,
              enter,
            ),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  createHeroWithTier$(
    signer: string,
    chainId: number,
    hero: string,
    name: string,
    refCode: string,
    tier: number,
    ngLevel: number,
    enter = true,
  ) {
    const data: HeroCreationData = {
      ngLevel,
      tier,
      enter: ngLevel > 0 ? false : enter,
      heroName: name,
      targetUserAccount: signer,
      refCode,
    };

    this.logger.trace('createHero', data);

    return from(this.createHeroController(chainId).createHero.estimateGas(hero, data)).pipe(
      concatMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Create hero',
            isNeedUpdateHero: true,
            isNeedUpdateBalances: true,
            txPopulated: this.createHeroController(chainId).createHero.populateTransaction(hero, data),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  setBiome$(account: string, chainId: number, hero: string, id: number, biome: number) {
    return from(this.createHeroController(chainId).setBiome.estimateGas(hero, id, biome)).pipe(
      concatMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Set biome',
            txPopulated: this.createHeroController(chainId).setBiome.populateTransaction(hero, id, biome),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  reborn$(account: string, chainId: number, hero: string, id: number) {
    this.logger.trace('reborn', hero, id);
    return from(this.createHeroController(chainId).reborn.estimateGas(hero, id)).pipe(
      concatMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Reborn',
            txPopulated: this.createHeroController(chainId).reborn.populateTransaction(hero, id),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: true,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }
}
