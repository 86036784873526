import { Injectable } from '@angular/core';
import { QuoterV2__factory, UniswapV3Pool__factory, UniswapV3Swap__factory, AlgebraPool__factory } from '@data/abi';
import { IV3SwapRouter } from '@data/abi/UniswapV3Swap';
import { environment } from '@environments/environment';
import { TransactionDataModel } from '@models/transaction-data.model';
import { ErrorService } from '@services/error.service';
import { FeesExtension, ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { ProviderService } from '@services/provider.service';
import { GET_DEX_ADDRESSES, GET_POOL_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { CHAIN_IDS } from '@shared/constants/chain-ids.constant';
import { adjustGasLimit } from '@shared/utils';
import { formatUnits } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { catchError, concatMap, forkJoin, from, retry, switchMap, map, of, tap, Observable } from 'rxjs';

import ExactInputSingleParamsStruct = IV3SwapRouter.ExactInputSingleParamsStruct;

type PriceData = {
  price: number;
  timestamp: number;
};

@Injectable({
  providedIn: 'root',
})
export class UniswapV3Service extends FeesExtension {
  priceCache: PriceData = { price: 0, timestamp: 0 };
  nativePriceCache: PriceData = { price: 0, timestamp: 0 };

  constructor(
    private providerService: ProviderService,
    private logger: NGXLogger,
    private errorService: ErrorService,
  ) {
    super();
  }

  // --- FACTORIES ---

  createUniswapV3Pool(pool: string, account = '') {
    return UniswapV3Pool__factory.connect(pool, this.providerService.getProviderForRead(account));
  }

  createUniswapV3SwapRouter(router: string, account = '') {
    return UniswapV3Swap__factory.connect(router, this.providerService.getProviderForRead(account));
  }

  createQuoterV2(quoter: string) {
    return QuoterV2__factory.connect(quoter, this.providerService.getProviderForRead());
  }

  createAlgebraPool(pool: string, account = '') {
    return AlgebraPool__factory.connect(pool, this.providerService.getProviderForRead(account));
  }

  // --- VIEWS ---

  sacraPrice$(chainId: number) {
    if (Number(environment['CHAIN_ID']) === CHAIN_IDS.NEBULA_TESTNET) {
      return of(0);
    }
    if (this.priceCache.timestamp > Date.now() - 1000 * 60 * 5) {
      return of(this.priceCache.price);
    }

    const sacraPool = GET_POOL_ADDRESSES(chainId).ethSacraPool;
    const nativePool = GET_DEX_ADDRESSES(chainId).NATIVE_USDC_POOL;
    return forkJoin([
      chainId === CHAIN_IDS.SONIC ? this.safelyGetStateOfAMM$(sacraPool) : this.slot0$(sacraPool),
      chainId === CHAIN_IDS.SONIC ? this.safelyGetStateOfAMM$(nativePool) : this.slot0$(nativePool),
    ]).pipe(
      map(([slot0Sacra, slot0Eth]) => {
        const priceSacraEth = this.calculateSacraPrice(chainId, slot0Sacra[0]);
        const priceEth = this.calculateEthPrice(chainId, slot0Eth[0]);
        this.logger.trace('SACRA => NET_COIN price', priceSacraEth);
        this.logger.trace('NET_COIN price', priceEth);
        const priceSacra$ = priceSacraEth * priceEth;
        this.logger.trace('SACRA price in $', priceSacra$);
        return priceSacra$;
      }),
      tap(price => {
        this.priceCache.price = price;
        this.priceCache.timestamp = Date.now();
      }),
    );
  }

  nativePrice$(chainId: number) {
    if (this.nativePriceCache.timestamp > Date.now() - 1000 * 60 * 5) {
      return of(this.nativePriceCache.price);
    }

    const priceSource$ =
      chainId === CHAIN_IDS.SONIC
        ? this.safelyGetStateOfAMM$(GET_DEX_ADDRESSES(chainId).NATIVE_USDC_POOL)
        : this.slot0$(GET_DEX_ADDRESSES(chainId).NATIVE_USDC_POOL);

    return this.fetchAndCacheNativePrice(priceSource$, chainId);
  }

  private fetchAndCacheNativePrice(priceSource$: Observable<any>, chainId: number) {
    return priceSource$.pipe(
      map(slot0Eth => {
        const priceEth = this.calculateEthPrice(chainId, slot0Eth[0]);
        this.logger.trace('NATIVE PRICE price', priceEth);
        return priceEth;
      }),
      tap(price => {
        this.nativePriceCache.price = price;
        this.nativePriceCache.timestamp = Date.now();
      }),
    );
  }

  slot0$(pool: string) {
    // this.logger.trace('slot0$', pool);
    return from(this.createUniswapV3Pool(pool).slot0()).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  safelyGetStateOfAMM$(pool: string) {
    this.logger.trace('safelyGetStateOfAMM$', pool);
    return from(this.createAlgebraPool(pool).safelyGetStateOfAMM()).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  // --- CALLS STATIC

  quoteExactIn(quoterv2: string, pool: string, tokenIn: string, tokenOut: string, amount: bigint) {
    this.logger.trace('quoteOut', pool, tokenIn);
    return from(
      this.createQuoterV2(quoterv2).quoteExactInputSingle.staticCall({
        tokenIn,
        tokenOut,
        fee: 10000,
        amountIn: amount,
        sqrtPriceLimitX96: 0,
      }),
    ).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  quoteExactOut(quoterv2: string, pool: string, tokenIn: string, tokenOut: string, amount: bigint) {
    this.logger.trace('quoteOut', pool, tokenIn);
    return from(
      this.createQuoterV2(quoterv2).quoteExactOutputSingle.staticCall({
        tokenIn,
        tokenOut,
        fee: 10000,
        amount,
        sqrtPriceLimitX96: 0,
      }),
    ).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  // --- CALLS ---

  wrapAndSwap$(
    router: string,
    tokenIn: string,
    tokenOut: string,
    fee: number,
    recipient: string,
    amountIn: bigint,
    amountOutMinimum: bigint,
    sqrtPriceLimitX96: bigint = 0n,
  ) {
    const params: ExactInputSingleParamsStruct = {
      tokenIn,
      tokenOut,
      fee,
      recipient,
      amountIn: 0n,
      amountOutMinimum,
      sqrtPriceLimitX96,
    };
    this.logger.trace('wrapAndSwap$', params);
    return forkJoin({
      wrap: from(
        this.createUniswapV3SwapRouter(router).wrapETH.populateTransaction(amountIn, {
          value: amountIn,
        }),
      ),
      exchange: from(this.createUniswapV3SwapRouter(router).exactInputSingle.populateTransaction(params)),
    }).pipe(
      concatMap(({ wrap, exchange }) => {
        // console.log(wrap);
        // console.log(exchange);
        return from(
          this.createUniswapV3SwapRouter(router)['multicall(bytes[])'].estimateGas([wrap.data, exchange.data], {
            value: amountIn,
          }),
        ).pipe(
          switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
          catchError(this.errorService.onCatchError),
          concatMap(gas => {
            return this.providerService.onChainCall(
              new TransactionDataModel({
                name: 'Swap to token',
                subgraphWaitUserData: false,
                showLoadingScreen: false,
                isSponsoredRelayPossible: this.providerService.chainId !== CHAIN_IDS.SEPOLIA,
                txPopulated: this.createUniswapV3SwapRouter(router)['multicall(bytes[])'].populateTransaction([
                  wrap.data,
                  exchange.data,
                ]),
                gasLimit: adjustGasLimit(gas),
                maxFeePerGas: this.maxFeePerGas,
                maxPriorityFeePerGas: this.maxPriorityFeePerGas,
                gasPrice: this.gasPrice,
                isDelegatedRelayPossible: false,
                value: amountIn,
              }),
            );
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  swapAndUnwrap$(
    router: string,
    tokenIn: string,
    tokenOut: string,
    fee: number,
    recipient: string,
    amountIn: bigint,
    amountOutMinimum: bigint,
    sqrtPriceLimitX96: bigint = 0n,
  ) {
    const params: ExactInputSingleParamsStruct = {
      tokenIn,
      tokenOut,
      fee,
      recipient: router,
      amountIn,
      amountOutMinimum,
      sqrtPriceLimitX96,
    };
    this.logger.trace('swapAndUnwrap$', params);
    return forkJoin({
      exchange: from(this.createUniswapV3SwapRouter(router).exactInputSingle.populateTransaction(params)),
      unWrap: from(
        this.createUniswapV3SwapRouter(router)['unwrapWETH9(uint256,address)'].populateTransaction(0n, recipient),
      ),
    }).pipe(
      concatMap(({ exchange, unWrap }) => {
        return from(
          this.createUniswapV3SwapRouter(router)['multicall(bytes[])'].estimateGas([exchange.data, unWrap.data]),
        ).pipe(
          switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
          catchError(this.errorService.onCatchError),
          concatMap(gas => {
            return this.providerService.onChainCall(
              new TransactionDataModel({
                name: 'Swap to network token',
                subgraphWaitUserData: false,
                showLoadingScreen: false,
                isSponsoredRelayPossible: this.providerService.chainId !== CHAIN_IDS.SEPOLIA,
                txPopulated: this.createUniswapV3SwapRouter(router)['multicall(bytes[])'].populateTransaction([
                  exchange.data,
                  unWrap.data,
                ]),
                gasLimit: adjustGasLimit(gas),
                maxFeePerGas: this.maxFeePerGas,
                maxPriorityFeePerGas: this.maxPriorityFeePerGas,
                gasPrice: this.gasPrice,
                isDelegatedRelayPossible: false,
              }),
            );
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  private calculateSacraPrice(chainId: number, slot0: bigint) {
    if (chainId === CHAIN_IDS.REAL) {
      return 1 / (2 ** 192 / (+slot0.toString()) ** 2);
    }
    return 2 ** 192 / (+slot0.toString()) ** 2;
  }

  private calculateEthPrice(chainId: number, slot0: bigint) {
    if (chainId === CHAIN_IDS.REAL) {
      return Number(2n ** 192n / slot0 ** 2n);
    }
    return 1 / +formatUnits(2n ** 192n / slot0 ** 2n, 12);
  }
}
