import { Dialog } from '@angular/cdk/dialog';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { HeroEntity, ItemEntity, UserEntity } from '@generated/gql';
import {
  continueToChamber,
  getHeroExperiencePercent,
  getHeroVirtualLevel,
  getNetworkTokenIcon,
} from '@helpers/data-helper';
import { Formatter } from '@helpers/formatter';
import { AttributesModel } from '@models/attributes.model';
import { HeroMetadataType } from '@models/graph/hero-metadata-type';
import { ItemEntityExtendedType } from '@models/item-entity-extended.type';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { HeaderStateModel } from '@services/app-state-service/models/header-state.model';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { Mediator } from '@services/mediator.service';
import { MultiCallService } from '@services/onchain/multi-call.service';
import { ReinforcementService } from '@services/onchain/reinforcement.service';
import { TokenService } from '@services/onchain/token.service';
import { UserControllerService } from '@services/onchain/user-controller.service';
import { ProviderService } from '@services/provider.service';
import { SoundService } from '@services/sound.service';
import { StorageService } from '@services/storage.service';
import { Web3authService } from '@services/web3auth.service';
import { AchievementsActions } from '@shared/actions/achievement.actions';
import { HeroActions } from '@shared/actions/hero.actions';
import { MenuActions } from '@shared/actions/menu.actions';
import { TutorialActions } from '@shared/actions/tutorial.actions';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { AccountDialogComponent } from '@shared/components/account-dialog/account-dialog.component';
import { AchievementsService } from '@shared/components/achievements/achievements.service';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { BalanceComponent } from '@shared/components/balance/balance.component';
import { BarStatComponent } from '@shared/components/bar-stat/bar-stat.component';
import { BottlesDialogComponent } from '@shared/components/bottles-dialog/bottles-dialog.component';
import { DailyPointsComponent } from '@shared/components/daily-points/daily-points.component';
import { InventoryComponent } from '@shared/components/hero-inventory/inventory/inventory.component';
import { HeroLevelUpComponent } from '@shared/components/hero-level-up/hero-level-up.component';
import { HeroStatItemInputInterface } from '@shared/components/hero-stat-item/hero-stat-item-input-interface';
import { LifeChancesComponent } from '@shared/components/life-chances/life-chances.component';
import { SettingsDialogComponent } from '@shared/components/settings-dialog/settings-dialog.component';
import { GET_CORE_ADDRESSES, GET_TOKEN_INFO } from '@shared/constants/addresses/addresses.constant';
import {
  ATTRIBUTE_TO_DESCRIPTION,
  ATTRIBUTE_TO_ICON,
  ATTRIBUTE_TO_NAME,
  ATTRIBUTES,
} from '@shared/constants/attributes.constant';
import { CHAINS } from '@shared/constants/chain-ids.constant';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { HERO_CORE_STATS_META } from '@shared/constants/hero-levels.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { TUTORIALS } from '@shared/constants/tutorial.constant';
import { countItems, getValueByKey } from '@shared/utils';
import { ethers, formatUnits } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { debounceTime, filter, finalize, of, switchMap, takeUntil, tap } from 'rxjs';

import { getAvatarByHeroClass } from '../../constants/heroes.constant';

import { ROUTE_WITH_EXCLUDED_BARS } from './constants/header.constant';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AvatarComponent,
    LifeChancesComponent,
    BarStatComponent,
    ButtonClickDirective,
    DailyPointsComponent,
    BalanceComponent,
    TranslateModule,
  ],
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() heroToken = '';
  @Input() heroTokenId = 0;
  @Input() menuState = { isOpen: false };
  @Input() providerInited = false;

  account: string;
  chainId: number;

  userName?: string;
  userAvatar?: string;
  virtualLevel = '0';
  accountFormatted: string = '';
  hero: HeroEntity | undefined;
  user?: UserEntity;
  items: ItemEntity[] = [];
  networkTokenBalance: string;
  gameTokenBalance = '0';
  magicTokenBalance = '0';
  strengthToken = '0';
  dexterityToken = '0';
  isShowBars = true;
  headerState: HeaderStateModel | undefined;
  avatarUrl: string;
  heroAttributes: AttributesModel;
  coreStatInfo: HeroStatItemInputInterface[] = [];
  additionalStatInfo: HeroStatItemInputInterface[] = [];
  isBuying = false;
  isAbleToClaimRewards = false;
  isNeedRepair = false;
  isAbleLevelUp = false;
  iconName: string = 'matic';
  isTokensTheSame = true;
  isHasTutorial = false;
  isHideMenuButton = false;
  magicTokenAdr: string;

  ATTRIBUTES = ATTRIBUTES;
  MAIN_ROUTES = MAIN_ROUTES;

  isPrivateKeyImported = false;

  notEnoughTokensInfoShowed = false;
  speedUpDialogOpened = false;

  tutorialsKeys = Object.keys(TUTORIALS);
  foundTutorialKey?: string;
  isHideMenuButtons = true;

  constructor(
    private router: Router,
    private providerService: ProviderService,
    private web3authService: Web3authService,
    private changeDetectorRef: ChangeDetectorRef,
    private appStateService: AppStateService,
    private tokenService: TokenService,
    private subgraphService: SubgraphService,
    private multiCallService: MultiCallService,
    private destroy$: DestroyService,
    private logger: NGXLogger,
    private location: Location,
    private dialog: Dialog,
    private soundService: SoundService,
    private translateService: TranslateService,
    private apiService: ApiService,
    private mediator: Mediator,
    private storageService: StorageService,
    private achievementsService: AchievementsService,
    private userControllerService: UserControllerService,
    private reinforcementService: ReinforcementService,
  ) {
    this.magicTokenAdr = GET_CORE_ADDRESSES(Number(environment['CHAIN_ID'])).magicToken;
  }

  ngOnInit(): void {
    this.checkBars(this.router.url);
    this.checkTutorial(this.router.url);

    let url = this.router.url;

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(
        debounceTime(300),
        tap(event => {
          url = (event as NavigationEnd).url;
        }),
        switchMap(() => {
          return this.loadHeroData();
        }),
      )
      .subscribe(() => {
        this.checkBars(url);
        this.checkTutorial(url);
      });

    this.mediator.ofAction(HeroActions.UpdateName, this.destroy$).subscribe(() => this.loadUserName());
    this.mediator.ofAction(MenuActions.MenuClose, this.destroy$).subscribe(() => this.closeMenu());

    this.appStateService
      .getHeaderStateObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        this.headerState = state;

        this.changeDetectorRef.detectChanges();
      });

    this.providerService.transactionEnded
      .pipe(filter(data => data.isNeedUpdateHero === true || data.isNeedUpdateBalances === true))
      .subscribe(data => {
        this.logger.trace('UPDATE HEADER INFO', data);
        if (data.isNeedUpdateHero) {
          this.loadHeroData();
          this.loadUserData();
          this.loadItems();
        }

        if (data.isNeedUpdateBalances) {
          this.loadBalances();
        }
      });

    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      (account: string) => {
        this.account = account;
        this.init();
      },
      (chainId: number) => {
        this.chainId = chainId;

        const core = GET_CORE_ADDRESSES(this.chainId);
        const gameToken = core.gameToken;
        const magicToken = core.magicToken;
        const strengthToken = core.strengthToken;
        const dexterityToken = core.dexterityToken;
        this.isTokensTheSame = magicToken === gameToken && strengthToken === gameToken && dexterityToken === gameToken;

        if (CHAINS.has(this.chainId)) {
          this.iconName = getNetworkTokenIcon();
        }

        this.init();
      },
    );
  }

  ngOnChanges({ heroToken, heroTokenId, providerInited }: SimpleChanges): void {
    if (heroToken || heroTokenId) {
      this.loadHeroData();
    }

    if (providerInited && providerInited.currentValue !== undefined) {
      this.checkBars(this.router.url);
    }

    if (this.heroToken === '' || this.heroTokenId === 0) {
      this.hero = undefined;
    }
  }

  init() {
    if (this.account && this.chainId) {
      this.isAbleToClaimRewards = false;
      this.loadHeroData();
      this.loadBalances();
      this.loadUserData();
      this.loadItems();
      this.loadUserName();
      this.loadLogo();
      this.getFormattedAccount();
    }
  }

  getFormattedAccount() {
    const userInfo = this.web3authService.getUserInfo();
    if (userInfo) {
      this.accountFormatted = userInfo.email.substring(0, 9) + '...';
    } else {
      this.accountFormatted = Formatter.formatAddress(this.account, 'short');
    }
    this.changeDetectorRef.detectChanges();
  }

  loadHeroData() {
    if (this.heroToken !== '' && this.heroTokenId !== 0) {
      this.logger.info('LOADING hero inf for header');
      this.subgraphService
        .hero$(this.heroToken, this.heroTokenId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(hero => {
          this.hero = hero as HeroEntity;
          this.virtualLevel = getHeroVirtualLevel(this.hero.stats.experience, this.hero.stats.level).toFixed();
          this.showNewAchievements(this.hero);

          this.isNeedRepair = hero.items.filter(i => i.durability / i.meta.durability <= 0.2).length > 0;
          this.isAbleLevelUp =
            this.heroExpPerc() >= 100 && this.hero.stats.level < 100 && hero.meta.feeToken.amount !== '0';
          this.logger.trace('this.isNeedRepair', this.isNeedRepair);
          this.logger.trace('this.isAbleLevelUp', this.isAbleLevelUp);

          this.loadStats();
          this.checkBars(this.router.url);

          this.avatarUrl = getAvatarByHeroClass(this.hero.meta.heroClass);

          this.changeDetectorRef.detectChanges();
        });
    } else {
      this.logger.info('Hero token or id is empty');
    }

    return of(void 0);
  }

  loadUserData() {
    if (this.account) {
      this.subgraphService
        .user$(this.account)
        .pipe(takeUntil(this.destroy$))
        .subscribe(user => {
          this.user = user as UserEntity;
          this.loadAbleToClaimRewards();
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  private loadItems() {
    if (this.account) {
      this.subgraphService
        .usersItems$(this.account)
        .pipe(
          finalize(() => {
            this.changeDetectorRef.detectChanges();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(items => {
          this.items = items;
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  loadUserName(): void {
    this.userControllerService
      .userAccountName$(this.chainId, this.account)
      .pipe(takeUntil(this.destroy$))
      .subscribe(name => {
        this.userName = name;
        this.changeDetectorRef.detectChanges();
      });
  }

  loadLogo(): void {
    this.userControllerService
      .userAvatar$(this.chainId, this.account)
      .pipe(takeUntil(this.destroy$))
      .subscribe(userAvatar => {
        this.userAvatar = userAvatar;
        this.changeDetectorRef.detectChanges();
      });
  }

  loadBalances() {
    if (!this.account || this.account === '0' || this.account === '') {
      return;
    }
    const core = GET_CORE_ADDRESSES(this.chainId);

    const calls = [
      this.multiCallService.getEthBalance(this.chainId, this.account),
      this.tokenService.balanceOf(core.gameToken, this.account),
      this.tokenService.balanceOf(core.magicToken, this.account),
      // this.tokenService.balanceOf(core.strengthToken, this.account),
      // this.tokenService.balanceOf(core.dexterityToken, this.account),
    ];
    this.multiCallService
      .aggregate$(calls, this.chainId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        const [nativeBalance, gameTokenBalance, magicToken] = result.returnData;

        this.tokenService.tokenBalanceCache.set(core.gameToken, BigInt(gameTokenBalance));
        this.tokenService.tokenBalanceCache.set(core.magicToken, BigInt(magicToken));
        // this.tokenService.tokenBalanceCache.set(core.strengthToken, BigInt(strengthToken));
        // this.tokenService.tokenBalanceCache.set(core.dexterityToken, BigInt(dexterityToken));
        this.tokenService.tokenBalanceCache.set(core.networkToken, BigInt(nativeBalance));

        this.networkTokenBalance = Formatter.formatCurrency(+formatUnits(nativeBalance));
        this.gameTokenBalance = (+formatUnits(gameTokenBalance)).toFixed(2);
        this.magicTokenBalance = Formatter.formatCurrency(
          +formatUnits(magicToken, GET_TOKEN_INFO(this.chainId, core.magicToken).decimals),
        );
        // this.strengthToken = Formatter.formatCurrency(
        //   +formatUnits(strengthToken, GET_TOKEN_INFO(this.chainId, core.strengthToken).decimals),
        // );
        // this.dexterityToken = Formatter.formatCurrency(
        //   +formatUnits(dexterityToken, GET_TOKEN_INFO(this.chainId, core.dexterityToken).decimals),
        // );

        // if (+ethers.formatUnits(nativeBalance) === 0) {
        //   // for some reason this message sometimes do not compile
        //   // hope this delay will help
        //   setTimeout(() => this.showNotEnoughTokensInfo(), 1000);
        // }

        // this.showSpeedUpDialog();

        if (this.chainId === -1) {
          // some testnet with refuel mechanic
          this.logger.trace('Check need refuel', +formatUnits(nativeBalance), +formatUnits(gameTokenBalance));
          if (+formatUnits(nativeBalance) < 0.1 || +formatUnits(gameTokenBalance) < 1) {
            this.apiService
              .refuel(this.account)
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.loadBalances();
              });
          }
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  // showNotEnoughTokensInfo() {
  //   if (this.notEnoughTokensInfoShowed) {
  //     return;
  //   }
  //   this.notEnoughTokensInfoShowed = true;
  //   const chain = CHAINS.get(this.chainId) as CHAIN_TYPE;
  //   this.dialog.open(InfoDialogComponent, {
  //     panelClass: 'app-overlay-pane',
  //     disableClose: true,
  //     data: {
  //       infoTitle: 'Not enough network coins!',
  //       infoDesc: this.translateService.instant(
  //         chain[CHAIN_FIELDS.IS_TEST_NET] ? 'block-app-buy-coins.test-net' : 'block-app-buy-coins.main-net',
  //         {
  //           net: chain[CHAIN_FIELDS.NAME],
  //           faucetLink: chain[CHAIN_FIELDS.FAUCET_LINK],
  //         },
  //       ),
  //       canClose: true,
  //     },
  //   });
  // }

  // showSpeedUpDialog() {
  //   if (this.isPrivateKeyImported || !this.web3authService.getUserInfo() || this.speedUpDialogOpened) {
  //     return;
  //   }
  //
  //   this.speedUpDialogOpened = true;
  //
  //   this.dialog.open(StorePrivateKeyDialogComponent, {
  //     panelClass: 'app-overlay-pane',
  //     disableClose: true,
  //     data: {},
  //   });
  // }

  showNewAchievements(hero: HeroEntity) {
    if (!hero || !this.account || hero.owner.id.toLowerCase() !== this.account.toLowerCase()) {
      return;
    }

    const newAchievements = this.achievementsService.getNewAchievements(this.storageService, hero);

    // only 1 achievement currently
    setTimeout(() => {
      this.mediator.dispatch(new AchievementsActions.Show(newAchievements[0]));
    }, 1000);
  }

  openAccountModal() {
    this.dialog.open(AccountDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: { account: this.account, chainId: this.chainId, userName: this.userName, userAvatar: this.userAvatar },
    });
  }

  goBack() {
    if (!this.headerState?.backUrl) {
      this.location.back();
    } else {
      this.router.navigate(this.headerState?.backUrl);
    }
  }

  handleMenu() {
    this.menuState.isOpen = !this.menuState.isOpen;
  }

  closeMenu() {
    this.menuState.isOpen = false;
  }

  openHome() {
    this.router.navigate([MAIN_ROUTES.MAIN]);

    this.closeMenu();
  }

  openSettings() {
    this.dialog.open(SettingsDialogComponent, {
      panelClass: 'app-overlay-pane',
    });

    this.closeMenu();
  }

  openInventory() {
    if (this.heroToken !== '' && this.heroTokenId !== 0) {
      this.soundService.play({ key: 'inventory' });

      const dialog = this.dialog.open(InventoryComponent, {
        panelClass: 'app-overlay-pane',
        data: { heroToken: this.hero?.meta.id, heroId: this.hero?.heroId },
      });

      dialog.closed
        .pipe(
          filter(isNeedToUpdate => isNeedToUpdate === true),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.mediator.dispatch(new HeroActions.Update());
        });
    }
  }

  openHeroes() {
    this.router.navigate([MAIN_ROUTES.MAIN, MAIN_ROUTES.HERO, this.heroToken, this.heroTokenId.toString()]);

    this.closeMenu();
  }

  openTopList() {
    this.router.navigate([MAIN_ROUTES.TOP_LIST]);

    this.closeMenu();
  }

  openLeaderboard() {
    this.router.navigate([MAIN_ROUTES.LEADERBOARD]);

    this.closeMenu();
  }

  openTransform() {
    this.router.navigate([MAIN_ROUTES.MAIN, MAIN_ROUTES.TRANSFORM, this.heroToken, this.heroTokenId.toString()]);

    this.closeMenu();
  }

  goToMap() {
    this.soundService.play({ key: 'go_to_map' });

    this.router.navigate([MAIN_ROUTES.MAIN, MAIN_ROUTES.MAP, this.hero?.meta.id, this.hero?.heroId]);

    this.closeMenu();
  }

  createHero() {
    this.router.navigate([MAIN_ROUTES.CREATE]);

    this.closeMenu();
  }

  openBottlesDialog() {
    if (this.hero && this.user) {
      const count = countItems(this.items);

      let items = [].map(i => i as ItemEntityExtendedType); // this.items.map(i => i as ItemEntityExtendedType).filter(it => it.meta.isConsumableItem);

      Object.keys(count).forEach(key => {
        const found = this.items.find(it => it.meta.name === key);
        if (found) {
          items = [
            ...items,
            { ...found, count: count[key], countFormatted: count[key] > 99 ? '99+' : String(count[key]) },
          ];
        }
      });

      this.dialog.open(BottlesDialogComponent, {
        panelClass: 'app-overlay-pane',
        data: {
          chainId: this.chainId,
          account: this.account,
          heroToken: this.heroToken,
          heroTokenId: this.heroTokenId,
          hero: this.hero,
          items,
        },
      });
    }
  }

  openTutorial() {
    if (this.foundTutorialKey) {
      this.mediator.dispatch(
        new TutorialActions.ToggleTutorial(true, TUTORIALS[this.foundTutorialKey], true, this.foundTutorialKey!),
      );
    }
  }

  returnToAdventure() {
    this.closeMenu();

    this.soundService.play({ key: 'dungeon_enter' });

    return continueToChamber(
      this.router,
      this.hero?.dungeon?.currentChamber?.chamber?.isBattle ?? false,
      this.hero?.dungeon?.currentChamber?.chamber?.isEvent ?? false,
      this.heroToken,
      this.heroTokenId,
      this.hero?.dungeon?.id ?? 'no_dung',
      this.hero?.dungeon?.currentChamber?.chamber?.id ?? 'no_chamber',
      this.hero?.dungeon?.currentChamberIndex ?? 0,
      this.hero?.dungeon?.currentChamber?.iteration ?? 0,
    );
  }

  openMarketplace() {
    this.router.navigate([MAIN_ROUTES.MAIN, MAIN_ROUTES.PAWNSHOP]);

    this.closeMenu();
  }

  openGuilds() {
    this.router.navigate([MAIN_ROUTES.MAIN, MAIN_ROUTES.GUILDS]);

    this.closeMenu();
  }

  heroExpPerc() {
    return Math.min(
      getHeroExperiencePercent(
        this.hero ? this.hero.stats.experience : 0,
        this.hero ? this.hero.previousLevelExperienceRequire : 0,
        this.hero ? this.hero.nextLevelExperienceRequire : 0,
      ),
      100,
    );
  }

  expBarWidth() {
    return Math.min(this.heroExpPerc(), 100) / 100;
  }

  loadAbleToClaimRewards() {
    if (!this.user) {
      return;
    }

    this.isAbleToClaimRewards = false;
    this.user.heroes.map(hero => {
      if (hero.reinforcementRewardTokens.length > 0 || hero.reinforcementRewardItems.length > 0) {
        this.isAbleToClaimRewards = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  buyTokens(tokenName: string) {
    if (this.isBuying) {
      return;
    }

    let tokenAdr;

    switch (tokenName) {
      case 'sacra':
        tokenAdr = GET_CORE_ADDRESSES(this.chainId).gameToken;
        break;
      case 'usdc':
        tokenAdr = GET_CORE_ADDRESSES(this.chainId).gameToken;
        break;
      case 'matic':
        tokenAdr = GET_CORE_ADDRESSES(this.chainId).gameToken;
        break;
      case 'tetu':
        tokenAdr = GET_CORE_ADDRESSES(this.chainId).gameToken;
        break;
      default:
        throw new Error('wrong token');
    }

    // if (!isSimpleTestNetwork(this.chainId)) {
    //   this.tokenService.openBuyTokenExternalLink(tokenAdr, this.chainId);
    //   return;
    // }

    this.isBuying = true;
    this.changeDetectorRef.detectChanges();

    this.tokenService
      .buyTokens$(this.account, '', tokenAdr, ethers.parseUnits('100'), this.chainId)
      .pipe(
        finalize(() => {
          this.isBuying = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (this.hero) {
          this.updateFeeBalance(this.hero.meta);
        }
      });
  }

  updateFeeBalance(hero: HeroMetadataType) {
    this.tokenService
      .balanceOf$(hero.feeToken.token.id, this.account)
      .pipe(takeUntil(this.destroy$))
      .subscribe(gameTokenBalance => {
        this.gameTokenBalance = Formatter.formatCurrency(+ethers.formatUnits(gameTokenBalance));

        this.changeDetectorRef.detectChanges();
      });
  }

  onShowLevelUpDialog() {
    this.dialog.open(HeroLevelUpComponent, {
      panelClass: 'app-overlay-pane',
      data: { heroToken: this.hero?.meta.id, heroId: this.hero?.heroId },
    });
  }

  onShowReinforcementDialog() {
    this.router.navigate([MAIN_ROUTES.HERO, this.hero?.meta.id, this.hero?.heroId, MAIN_ROUTES.REINFORCEMENT]);
  }

  onShowRepairDialog() {
    this.openInventory();
  }

  isShowThunderbolt() {
    return this.providerService.isLocalPK();
  }

  onSessionSettings() {
    if (this.web3authService.getUserInfo()) {
      this.router.navigate([MAIN_ROUTES.WEB3AUTH_SETTINGS]);
    } else {
      this.router.navigate([MAIN_ROUTES.DELEGATE]);
    }
  }

  private checkBars(url: string) {
    const cleanUrl = url.indexOf('?') >= 0 ? url.substring(0, url.indexOf('?')) : url;

    this.isHideMenuButtons = cleanUrl === '/' || cleanUrl === `/${MAIN_ROUTES.TOP_LIST}`;
    this.isHideMenuButton = cleanUrl === '/' || (!this.providerInited && cleanUrl === `/${MAIN_ROUTES.TOP_LIST}`);

    this.isShowBars = !ROUTE_WITH_EXCLUDED_BARS.some(it => cleanUrl.includes(`/${it}/`) || cleanUrl.includes(`/${it}`));

    this.changeDetectorRef.detectChanges();
  }

  private checkTutorial(url: string) {
    this.foundTutorialKey = this.tutorialsKeys.find(it => url.indexOf(it) >= 0);

    // if (this.foundTutorialKey) {
    //   this.isHasTutorial = true;
    //
    //   this.changeDetectorRef.detectChanges();
    // }
  }

  isSonicChain(): boolean {
    return Number(environment['CHAIN_ID']) === 146;
  }

  private loadStats() {
    this.heroAttributes = AttributesModel.fromStringArray(this.hero ? this.hero.attributes : []);

    this.coreStatInfo = HERO_CORE_STATS_META.map((stat): HeroStatItemInputInterface => {
      return {
        ...stat,
        text: getValueByKey<AttributesModel>(this.heroAttributes, stat.id),
        attributeIndex: stat.index ?? 0,
      };
    });

    this.additionalStatInfo = [
      {
        id: 'lightningResistance',
        name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
        icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
        text: this.heroAttributes.lightningResistance,
        description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.LIGHTNING_RESISTANCE) ?? '',
        attributeIndex: ATTRIBUTES.LIGHTNING_RESISTANCE,
      },
      {
        id: 'fireResistance',
        name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
        icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
        text: this.heroAttributes.fireResistance,
        description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.FIRE_RESISTANCE) ?? '',
        attributeIndex: ATTRIBUTES.FIRE_RESISTANCE,
      },
      {
        id: 'coldResistance',
        name: ATTRIBUTE_TO_NAME.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
        icon: ATTRIBUTE_TO_ICON.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
        text: this.heroAttributes.coldResistance,
        description: ATTRIBUTE_TO_DESCRIPTION.get(ATTRIBUTES.COLD_RESISTANCE) ?? '',
        attributeIndex: ATTRIBUTES.COLD_RESISTANCE,
      },
    ];
  }

  protected readonly CHECKBOX_STATE = CHECKBOX_STATE;
}
