import { CoreAddressesModel } from '@models/core-addresses.model';
import { PoolAddressesModel } from '@models/pool-addresses.model';
import { TokenInfo } from '@shared/constants/addresses/addresses.constant';

export const NEBULA_TESTNET_CORE = new CoreAddressesModel(
  '0x498a2f15c16C9e9819A5207649Bf90ffc8474702', // controller
  '0x684B0790CB82dde487CaCdDb866c5E6635e303B5', // gameToken
  '0x0d24278Ad57cC36792F4397182aDfA0324Fc7FAe', // minter
  '0xD03e538b57b61Cb31D69D5e66343162D12F8e083', // goc
  '0xd55c13030fEB513953dDa301c469F413D4eFA06d', // reinforcementController
  '0x61D5704BC0D06e13b25820587600314f4e2F9140', // dungeonFactory
  '0xFf98dC6D58eA757550B3C5f1911417f3560b1ccf', // heroController
  '0xdE6A5E74c3FcAEA3D87868aDe0c2124B856505dd', // itemController
  '0x475938f2F16c9dfD8339918Da525C35D59D4eaee', // oracle
  '0x19F31da19f72d7920f7310fe7fd63574B378eFB4', // statController
  '0x65729eaDB184e63d64b345A1D1Bcfa188288386a', // storyController
  '0xEf19e21312bA4EA1947864F1bB2Cc898A8D731b8', // treasury
  '0x23842A847f356Ff453b8b09a26CBc01754262B10', // statReader
  '0x237cCD1675FA57ed2840b2646B58217Be818e274', // multicall
  '0xeF54B53Ef50C1dE54A5D86bbfFFcbAf01B7c01E9', // magicToken (USDC)
  '0x684B0790CB82dde487CaCdDb866c5E6635e303B5', // strengthToken
  '0x684B0790CB82dde487CaCdDb866c5E6635e303B5', // dexterityToken

  '0xBAf226332FfbD1a0480e85b4eC86FbE629b5D3E9', // pawnsop
  '0xffA6Ceb79Be00fa5836Ca156991e399C6A121274', // PawnShopRouterFactory
  '', // network token
  '', // sponsored hero
  '0x52CEba41Da235Af367bFC0b0cCd3314cb901bB5F', // relay
  '0xeFBc16b8c973DecA383aAAbAB07153D2EB676556', // data storage
  '0xF98d93492b1DD0AE3eaabA182DF81969Ab6A6150', // user controller
  '0x4425A1d65Ae1554079AF9903b5080D19477E6C23', // item controller helper
  '0x7023808bEd53e902cD5dDAA346E09b65F5e879EF', // guild controller
  '0xD957d66ad0A4F14ca2832588098575DbE1923548', // shelterController
  '0xde750eAa6e88523C4504B1A90492Ea376Aa61D2C', // shelterAuctionController
  '0xc9bb88A605F3e285D948142A7c23AcFAFB77f2Fc', // rewardsPool
  '0xe2cE25c3c4e49C4C63486d1d442B1AeE85c54810', // pvp controller
);

export const NEBULA_TESTNET_TOKEN_INFO = new Map<string, TokenInfo>([
  [NEBULA_TESTNET_CORE.gameToken.toLowerCase(), { symbol: 'SACRA', decimals: 18 }],
  [NEBULA_TESTNET_CORE.magicToken.toLowerCase(), { symbol: 'USDC', decimals: 18 }],
  [NEBULA_TESTNET_CORE.networkToken.toLowerCase(), { symbol: 'sFUEL', decimals: 18 }],
]);

export const NEBULA_TESTNET_POOLS = new PoolAddressesModel('');

export const NEBULA_TESTNET_DEX = {
  QUOTER_V2: '',
  SWAP_ROUTER_V2: '',
  NATIVE_USDC_POOL: '',
};

export function getNebulaFaucetByToken(token: string) {
  if (NEBULA_TESTNET_CORE.gameToken.toLowerCase() === token.toLowerCase()) {
    return '0xc14D941b04744A4C6d4cf22B2931358C5d2A48B8';
  } else if (NEBULA_TESTNET_CORE.magicToken.toLowerCase() === token.toLowerCase()) {
    return '0xF504aD676723B13d4d0B112F8039F5A0fcb27E56';
  } else {
    throw Error('No faucet for token ' + token);
  }
}

export const NEBULA_TESTNET_ITEMS = {
  SACRA_CONS_1_ITEM: '0x6502770B7eCD88653E9A9e17c9FA13ef0859052c',
  SACRA_CONS_2_ITEM: '0x7998D90875687f4FabeDc1d1917B6f97463ACaB0',
  SACRA_CONS_3_ITEM: '0x1981C357b278843f6A0A09175CB49Ea157161F81',
  SACRA_CONS_4_ITEM: '0x9dA58d6c34479C48bcF3513ae0d2E120E06FBD76',
  SACRA_CONS_17_ITEM: '0x1014987a6c413ae83F89c476dB5bc655944d587a',
  SACRA_CONS_18_ITEM: '0x926118544F16e37F87BE284a5A58c408cEBB6191',
  SACRA_CONS_19_ITEM: '0x475254dFB052713846e0f9eB1f885E1F89c8dbBA',
  SACRA_CONS_20_ITEM: '0x9A9A91a970B146325C8008fDde2CFe3A179B8045',
  SACRA_CONS_21_ITEM: '0x54Fb7BA2bc3BcA635Cb45F2ff44FfA8E34276a80',
  SACRA_CONS_27_ITEM: '0x6D5809B19470f4803cf88a0F812944Ad9F1351AC',
  SACRA_CONS_28_ITEM: '0xBAD88729534DC37FD62d1b9c3f6e7da82e6F4BfD',
  SACRA_CONS_29_ITEM: '0xf5fb468E5B63B13aeBbf895A5EE70F949dAe3840',
  SACRA_CONS_30_ITEM: '0xC447A557A97dfc20F51BDAf25FA68F62615a478C',
  SACRA_CONS_31_ITEM: '0x0686f06F878b40c691318C22b3b083b41f9823B3',
  SACRA_CONS_32_ITEM: '0xd2AD7bD03224B9BF281D42F2F862FdEC9D36061E',
  SACRA_CONS_35_ITEM: '0x7a74Ee46c034847E7e695cC33da062C06a1b42cf',
  SACRA_OTHER_1_ITEM: '0x94Ab0Afc1E60EE6acDEB1dF659744c2506ec5DA6',
  SACRA_OTHER_2_ITEM: '0xC398082C3Bf667C6E5d5783B2dFEFb1a1626F4A8',
  SACRA_OTHER_3_ITEM: '0x25E42BD14a4c2AE51136e76F3C4780CecC88B739',
  SACRA_OTHER_4_ITEM: '0x7E664B630519a8A3132CC0C8DE8Ba48c8942Fc16',
  SACRA_OTHER_5_ITEM: '0x1C47DF490031eA629fDc801A45c1e9E81e17EAf5',
  SACRA_HELM_1_ITEM: '0xc5D3F675E7f3752C3612475204b6B80f234C9308',
  SACRA_HELM_6_ITEM: '0x0d4A748E20C82F80b6f5F3BE90BF42a65B92bd7C',
  SACRA_HELM_9_ITEM: '0xB4092CFd0F5747EDeD437C53b82f5005C807Cb9E',
  SACRA_BODY_1_ITEM: '0x77b095C82E3EC8941F596B4bd0a007d4b87dB15a',
  SACRA_GLOVES_1_ITEM: '0x96f7a99162D62dDE9b8004f348f85F54ecb5912d',
  SACRA_GLOVES_6_ITEM: '0x35286d021a553DbC653d4E9D6210BE70f1B7CDe1',
  SACRA_BELT_1_ITEM: '0x17dab6057384BcA1199Ca6F9a46c23c0D18D4506',
  SACRA_BELT_5_ITEM: '0xa415CeCCf4f4b5893BB50542CE20635F6dDDcA9a',
  SACRA_AMULET_1_ITEM: '0x575Bd9c73E3590516496a1660FC3d0b4a0a9c225',
  SACRA_AMULET_2_ITEM: '0x4472adDce2cC0A071969843dCA3271D8716b3188',
  SACRA_AMULET_12_ITEM: '0x75d5353744B574aAaaBC25FbC056A32DaaE0859E',
  SACRA_AMULET_13_ITEM: '0x24733fE1f15CCe076b4357EaC763b7A49053Dd09',
  SACRA_RING_1_ITEM: '0x61920437D7342C8d514Db293c0754a4A7514624D',
  SACRA_RING_2_ITEM: '0x81bD7359978c7e03268A5A06c6da7150B3024107',
  SACRA_RING_9_ITEM: '0x1b567E10Ab50C74805b24C6f51d2c033f79bEad6',
  SACRA_OFF_1_ITEM: '0x7Ee8316807543E4239c5EDAfa246899B63cc92E0',
  SACRA_OFF_2_ITEM: '0xF039F91E7e18bf93aDbf2b914808ED2F75eb322e',
  SACRA_OFF_10_ITEM: '0xf914ff2B8db291ccC641F4E83DFb3921ea9470D5',
  SACRA_BOOTS_1_ITEM: '0x89f28EAF2fa375c6F786c1ec6E95D78bCdA6e64E',
  SACRA_BOOTS_5_ITEM: '0xeeB28002cC335eF50411A21E97D44230c30E4BA9',
  SACRA_OHW_1_ITEM: '0xc69534dbC4A9a3C5b345D6a875D73fBc6405073B',
  SACRA_OHW_2_ITEM: '0xbb5999607af5A17aE961976240d73017882a48fF',
  SACRA_OHW_3_ITEM: '0x6B316EE0EFf7162f1c0274B13efe8626968dE92a',
  SACRA_THW_1_ITEM: '0xBEB97e3b0223c5344936e88c395FD9Db86d93d44',
  SACRA_THW_2_ITEM: '0x92533854C2EB1036eF9ED8E6632D17F49A958fE7',
  SACRA_FEAR_ITEM: '0xF2b634f41cB84dA77eB6F3c1fFda275De5423Ba6',
  SACRA_DEMON_SPEED_ITEM: '0x039B40Fbc7CEE35Ea9462cFd0d2731ccA919cE50',
  SACRA_BRUTE_FORCE_ITEM: '0x5f86c8d5c36a68D11d6C607E00e848Fb37885906',
  SACRA_SAVAGE_CHARM_ITEM: '0x7A0F17B3AC1d3A4fAFE116db3Ae6CC161f77dE33',
  SACRA_GOLDEN_EYE_ITEM: '0x077488e71a97770379178194d3C4cbd6009eAFbB',
  SACRA_SABBAH_POISON_ITEM: '0x4c542546161ab703b55393F3d810850815ecA8fC',
  SACRA_SPIT_IN_FACE_ITEM: '0x1b61542658BA0B1E24CcD4483732A7450D3C2EAf',
  SACRA_ONSLAUGHT_ITEM: '0x41AA6D6aE859459a45380f4d48d8849c232aeD02',
  SACRA_STRONG_BLOCK_ITEM: '0x41f329F1B1cA52D58Dc57d26f662F3f5dc84cAdB',
  SACRA_BEEF_PATTY_ITEM: '0xEa2a813B09d54a85fc85A88A0fd122E66AB41DCc',
  SACRA_HEAT_STRIKE_ITEM: '0xc38343Def54c68ebE4CA956FF8779eF2CF1341c3',
  SACRA_STATIC_DISCHARGE_ITEM: '0x7CabE7bB00EE053eb56B182eF301aA572AE5c628',
  SACRA_CONS_5_ITEM: '0xAF74e0268Cd9BF17481456e31cB3B1c5498f66fE',
  SACRA_CONS_6_ITEM: '0x861341586CABD6dC9506fAF444FfAEed9dCa5D64',
  SACRA_CONS_7_ITEM: '0xCE98E2962e29eD7434d3d2ee2c6276C80e63Dd58',
  SACRA_CONS_8_ITEM: '0xB4382b9f282f3282A42586bb5e84F4772Aa9F2a2',
  SACRA_CONS_22_ITEM: '0x8fc5ED8b3b298bb2dc44E316b2023903508cf0E1',
  SACRA_HELM_2_ITEM: '0xEB7732191a29394981a1Cb256Bf12190fCCdbF4e',
  SACRA_HELM_7_ITEM: '0xF7f128d3F68dd8104bFc8E31f0E84e148F4Fc2D6',
  SACRA_BODY_2_ITEM: '0x8d26C7cB0697Ca55B61E01C575432cDfFEAC5F79',
  SACRA_BODY_6_ITEM: '0xf91CF9216bA83A05324c2909dEC2C091F1d42F44',
  SACRA_BODY_8_ITEM: '0x111cFCBF289E11532437127d00212EF0f224dD36',
  SACRA_GLOVES_2_ITEM: '0x86108cd00992873cF7f6E093b73066499d43f568',
  SACRA_GLOVES_7_ITEM: '0x0BB54041927B5820b728bD5B8F11045996b25cb6',
  SACRA_BELT_2_ITEM: '0x559F5cB74ED9DE15aaC278392c3f231F523D9995',
  SACRA_AMULET_3_ITEM: '0x09e176Fc37e6a0b1de91A4e404f49a0748AD30b8',
  SACRA_AMULET_4_ITEM: '0xb54b8D7eC6FaaF26FF0eA4D1B7a0539C757a847E',
  SACRA_AMULET_9_ITEM: '0x128C5842B8B50403688B11Dcf0De486f808b246a',
  SACRA_RING_3_ITEM: '0x1Bd2365B2147dD7642929F7185e0C1508D1950aa',
  SACRA_RING_4_ITEM: '0x035C9E5F3793D7690Dab692033f135A608155464',
  SACRA_RING_10_ITEM: '0x5D52Efc74Bb63Bd517724b0987593A1E0062FB17',
  SACRA_RING_11_ITEM: '0xb9bcb9ad24359143d3BAEa9949EA2Ae9FE8a3B89',
  SACRA_OFF_3_ITEM: '0x55B2F029F2Bb7D763C789aE9b0AF3eAa73Fb9c36',
  SACRA_OFF_4_ITEM: '0xE3F6a4FDB8086A8D84302EF9a789e244639f7847',
  SACRA_OFF_11_ITEM: '0xE3141C89B88C1aba0218B7A4b589A80f8E4e7230',
  SACRA_BOOTS_2_ITEM: '0x4CC97adB316b70847C825a4bd6F7D8e45eD0e958',
  SACRA_OHW_4_ITEM: '0xA46F11Be25BD090fDbc996a61F468f81F89F863D',
  SACRA_OHW_5_ITEM: '0x7422cC9Fc25eC4C64A70d3baD1ebc65A74016813',
  SACRA_OHW_6_ITEM: '0xB0a4c94726F3227C409D92dfD6B042aa6c71DD6e',
  SACRA_THW_3_ITEM: '0x67abFbCcD992131AC5B39390e1b9B21089aDb0c6',
  SACRA_THW_4_ITEM: '0x038C78DC16D46bE23d3063dC9D3b4d52f85cA818',
  SACRA_THW_5_ITEM: '0xc8D6Ed6bd1AD6dc4d2C45C26dc974423C46A0F11',
  SACRA_STEEL_SKIN_ITEM: '0x510B86425c95dd1EF8FF750A29b60DB75b2B2A99',
  SACRA_LIFEDRAIN_ITEM: '0x5e61B91669274b33f6CA3e56eA80e33F06bA206e',
  SACRA_FROSTBITE_ITEM: '0x14c54962F5F55b699A2B19D9e03378693e20B2A0',
  SACRA_LIKE_A_PUSSY_ITEM: '0xaCdc4f2A3047F32f07EFBf0483D95368C07818bd',
  SACRA_RAGE_ITEM: '0xFD25385703cd3A13877268Dc4d0c3883856e6CfE',
  SACRA_DEAD_STRIKE_ITEM: '0x39493Ad079Ee61d432AE0623D6D8C3e9c866Ad59',
  SACRA_FIRM_HAND_ITEM: '0x1e9eE04026bd6579A8B71b40A3Ccc88706579B57',
  SACRA_JUSTICE_ITEM: '0x711aad78F192D075e15fF2c32FAFd26400AA297c',
  SACRA_STEP_BACK_ITEM: '0xE44A1cD720FE1711d7B5aC2d389d62E604ADbFD4',
  SACRA_PATIENCE_ITEM: '0x0686f346f217aD5658080852F3F5a761aD2c3cE9',
  SACRA_TIME_AND_EXPERIENCE_ITEM: '0x6fA35b30c7eFBa254eB927880e9B81F280356700',
  SACRA_MELT_ITEM: '0x32CDAAB1432af09D831bEB2AeB240f3a0f0F1dFA',
  SACRA_ICE_SHARD_ITEM: '0xde08ab1E5d80Dce2BffDCB248D9e5291Aa15457D',
  SACRA_CONS_9_ITEM: '0xc51bb43FdE7493cF8eceF283E95FdAE9e5863F13',
  SACRA_CONS_10_ITEM: '0x785D6283d40C8Ab2445905c1D4C48E4C1B8DaA57',
  SACRA_CONS_11_ITEM: '0xd0fA3499Dbd1D0d88e5c0954A0c721e7ef3740bF',
  SACRA_CONS_12_ITEM: '0x084cD8a1C3b3B12efB0A0F66F53a7A7F1Ebe7873',
  SACRA_CONS_23_ITEM: '0xAb058Df7d754bB885D6a891a8B499a409F7A0f6a',
  SACRA_CONS_24_ITEM: '0x00cd5dc7C98fd9e47BD9C4B7aA39722801164Cc4',
  SACRA_HELM_3_ITEM: '0xDf3C2780EC7e6Af566164fdEC73B6EA26d549cde',
  SACRA_HELM_8_ITEM: '0x231FC34440593B27ed61869055b4de8E05D7529C',
  SACRA_BODY_3_ITEM: '0x106fCEEDa673fcBA005C612Cd95F084d0a95Fc45',
  SACRA_BODY_7_ITEM: '0xD71c2BdA2D3f2953e5163994F86C9A85DE3B4d1f',
  SACRA_GLOVES_3_ITEM: '0x426e2974B8274705168c9685Eaa036985DcD8BcC',
  SACRA_GLOVES_8_ITEM: '0x54eCE012dAD8Dbd72923b6FCE148e34c9A24b1B0',
  SACRA_BELT_3_ITEM: '0x8F0f607B4317d52494f095C5C9B22d66560b9Fbe',
  SACRA_AMULET_5_ITEM: '0xbD945676c4bb119698e00bC57F43a6D170C43Bc2',
  SACRA_AMULET_6_ITEM: '0x439FF6705F06A992E229B0894D0128f0D0966Aaf',
  SACRA_AMULET_10_ITEM: '0x0AA5Cd74d1B505C69e4ec99B6d0d0c41bB9A2Bcf',
  SACRA_AMULET_11_ITEM: '0x003AfB55bC4D25B7a3eF4BBEc35ccc6F9c4FC3BF',
  SACRA_RING_5_ITEM: '0xF0883c20d0be2Bd9522A9dB4174fC5EBBAB0c411',
  SACRA_RING_6_ITEM: '0x8bAAC8aaff1597a085dFbd5dd06e66477872360d',
  SACRA_OFF_5_ITEM: '0xF833C4BBe4d872b5aD1DD22Bb9b493986E5Fc065',
  SACRA_OFF_6_ITEM: '0x6CCE0BaA2d49dE029A12D9440d4e1c22C625EAA0',
  SACRA_BOOTS_3_ITEM: '0x7dA0547FD839bd2ff8EC4833be8174E76e31bf8d',
  SACRA_BOOTS_6_ITEM: '0xcfd005530063E947A09F13D123D5facd53bdBdDA',
  SACRA_OHW_7_ITEM: '0x3CA62f611c17329135ed367e8C64B9E3b1Af6B59',
  SACRA_OHW_8_ITEM: '0x749ED6BdE4475BC4D76cc32937bd52a9D6313b54',
  SACRA_OHW_9_ITEM: '0x47147D32E18C455f3237FA69dFDa6B89c6A8305d',
  SACRA_OHW_12_ITEM: '0x43b972714432494d6764ec3439Af772AE78ea12F',
  SACRA_OHW_13_ITEM: '0xed4B42175531A3f78691b7e9aC61710987166785',
  SACRA_OHW_14_ITEM: '0x77636665d4c8278FC41581766E1010091CA5CB1E',
  SACRA_THW_6_ITEM: '0x5B74cc09901Fcc064745F98b8BaEb7249892DfdD',
  SACRA_THW_7_ITEM: '0xDE159648b7c92FAA4B21cBB06ea9C40c63F46b96',
  SACRA_THW_10_ITEM: '0xa743b212e6a5D0e162d1201348b1F226a50C0FDa',
  SACRA_PARALYZE_ITEM: '0x2D14e38857EFeF8ad03851c7C4286b9dfB6A96DE',
  SACRA_GUIDING_LIGHT_ITEM: '0xc19fFc5dD6232E0D5c8Ba9C9Eb7E4bdD97d76C07',
  SACRA_GUT_PUNCH_ITEM: '0x659BF2DF5A30b178Ae245c01fDdB144D1917A05e',
  SACRA_LAST_DANCE_ITEM: '0x77b3A462afCF80247FA9EF88F8a67B828306942d',
  SACRA_I_SURVIVE_ITEM: '0x64A450EF728D7D7c46e8A9A3338cCD2833E72508',
  SACRA_ARMOR_OF_FAITH_ITEM: '0xeF4E6731237B2F5CC7764138d01Ea387c1e527c9',
  SACRA_THUNDERBOLT_ITEM: '0x60dC98A1f3B336f8c74dD1daB68Edb6090aDCBeC',
  SACRA_CONS_13_ITEM: '0x6Be36Cd6904F46E17f04af5cd5fb5c4055bA3c1d',
  SACRA_CONS_14_ITEM: '0x3624bFA456545778bba007790d99E3DF16D89ed4',
  SACRA_CONS_15_ITEM: '0xA391083928b227a65AEB0f8a45Ef57D967c510fC',
  SACRA_CONS_16_ITEM: '0x574358eaFBdEc9ace79a52e545622A9795FD7fA8',
  SACRA_CONS_25_ITEM: '0xc49277D0e09cEC0Aa8085E93Ea20483D5E8A5243',
  SACRA_CONS_26_ITEM: '0xE43f8E515D8AaeE98684F39c8dAb7b7CF2a9b19E',
  SACRA_HELM_4_ITEM: '0xC0034AE256cAd60CCd8F2581C4cBAECeb3078FB3',
  SACRA_HELM_5_ITEM: '0xC4073eA68F82ab630C5c0b302c22E265a33b42AF',
  SACRA_BODY_4_ITEM: '0xc7d2fe5DD345a3B92f405429A97E4864944E890D',
  SACRA_BODY_5_ITEM: '0xB6e624D07D265047726Fb92175022e017aADa260',
  SACRA_GLOVES_4_ITEM: '0xedc9C48d2A8561eF4c946Bf4B831937B88276D3F',
  SACRA_GLOVES_5_ITEM: '0x793006B9FAF02dE8dA70F81D5a1c2e0Cf7680Fac',
  SACRA_BELT_4_ITEM: '0x3A03e9767B89143E00688f9e0244def8EF90B568',
  SACRA_AMULET_7_ITEM: '0x215287d9F4Ffa5299b0b8E203fBb253370610be3',
  SACRA_AMULET_8_ITEM: '0xE6f7adA7668fAAAEE7bB88D249e0D6ac37eA12a3',
  SACRA_RING_7_ITEM: '0xBE5717167D23f043546dDf2881f338d462033615',
  SACRA_RING_8_ITEM: '0x8A7eFda2872E44f0B91d68baCCb78FCaB5AcF1bA',
  SACRA_OFF_7_ITEM: '0x9A449cdC926DB14F6c56e1E98C5d88e05586704d',
  SACRA_OFF_8_ITEM: '0x43E10536C77f175ead9FE096C6cFD17048B63590',
  SACRA_OFF_9_ITEM: '0x9a0534b8Da787C653b42992a19b49c0953a2c4c4',
  SACRA_OFF_12_ITEM: '0x27D8eD7F325EDA2b6111c32b4CAa307568d3Fa1f',
  SACRA_OFF_13_ITEM: '0x83f7eA928b3F429a58c6Ea8d3dA2B552b08e7263',
  SACRA_BOOTS_4_ITEM: '0x2b61E54C54CF0804582FCB591638aAD2d5fbdF51',
  SACRA_BOOTS_7_ITEM: '0xA46792a3a1d1C074eB3818E1f9C47054B1DB5Df8',
  SACRA_OHW_10_ITEM: '0xcB87FED29D052D1f546923B1601138F627B87aB7',
  SACRA_OHW_11_ITEM: '0xce54218b49654A020dAFc11a4069B85338FB5B7d',
  SACRA_THW_8_ITEM: '0x59368f157Ce4df9FA0701b3AA9429f6c7A1406C4',
  SACRA_THW_9_ITEM: '0xc63C0a13B803893980f06cE9E1E44aa71188dE61',
  SACRA_THW_11_ITEM: '0x67091Ab5D01641f66F2B8F973c5AbeC21DE10f4C',
  SACRA_REFLECTION_ITEM: '0x43817756D0A93A023636479e3d1b5B9Cff024E79',
  SACRA_SCARLET_BRANCH_ADEPT_ITEM: '0xF97620052D17486ef83BFf11ed3EFC017fE60837',
  SACRA_ANATHEMA_ITEM: '0xBc3E9D5e0cDE2208c8643B47E192C04BbbBff323',
  SACRA_GIANTS_BLOOD_ITEM: '0x083C5C218Bd6C3666F1B72580b054e2Eda2437C2',
  SACRA_BACKSTAB_ITEM: '0x1D722b9C0E6CE27Eaefa317aa5E60D78ec7eFDAD',
  SACRA_VETERAN_CONFIDENCE_ITEM: '0x9391a06E319D514ea56Dd368Dc17fbf2634FDB02',
  SACRA_LIVE_FORTRESS_ITEM: '0x9A7a30140617f618743298EaDCcD578025eA8fdA',
  SACRA_COLD_SHOULDER_ITEM: '0xf963E9e710f179bDBc82b1A10cE28126B71240A7',
  SACRA_ERASE_ITEM: '0xf5b50E25DB90389EA2E3f572d6088A7cb054F589',
};
