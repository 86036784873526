export class CoreAddressesModel {
  public readonly controller: string;
  public readonly gameToken: string;
  public readonly minter: string;
  public readonly goc: string;
  public readonly reinforcementController: string;
  public readonly dungeonFactory: string;
  public readonly heroController: string;
  public readonly itemController: string;
  public readonly oracle: string;
  public readonly statController: string;
  public readonly storyController: string;
  public readonly treasury: string;

  public readonly statReader: string;
  public readonly multicall: string;

  public readonly magicToken: string;
  public readonly strengthToken: string;
  public readonly dexterityToken: string;

  public readonly pawnshop: string;
  public readonly pawnshopRouterFactory: string;
  public readonly networkToken: string;
  public readonly sponsoredHero: string;
  public readonly relay: string;
  public readonly dataStorage: string;
  public readonly userController: string;
  public readonly itemControllerHelper: string;
  public readonly guildController: string;
  public readonly shelterController: string;
  public readonly shelterAuctionController: string;
  public readonly rewardsPool: string;
  public readonly pvpController: string;

  constructor(
    controller: string,
    gameToken: string,
    minter: string,
    goc: string,
    reinforcementController: string,
    dungeonFactory: string,
    heroController: string,
    itemController: string,
    oracle: string,
    statController: string,
    storyController: string,
    treasury: string,
    statReader: string,
    multicall: string,
    magicToken: string,
    strenghtToken: string,
    dexterityToken: string,
    pawnshop: string,
    pawnshopRouterFactory: string,
    networkToken: string,
    sponsoredHero: string,
    relay: string,
    dataStorage: string,
    userController: string,
    itemControllerHelper: string,
    guildController: string,
    shelterController: string,
    shelterAuctionController: string,
    rewardsPool: string,
    pvpController: string,
  ) {
    this.controller = controller;
    this.gameToken = gameToken;
    this.minter = minter;
    this.goc = goc;
    this.reinforcementController = reinforcementController;
    this.dungeonFactory = dungeonFactory;
    this.heroController = heroController;
    this.itemController = itemController;
    this.oracle = oracle;
    this.statController = statController;
    this.storyController = storyController;
    this.treasury = treasury;
    this.statReader = statReader;
    this.multicall = multicall;
    this.magicToken = magicToken;
    this.strengthToken = strenghtToken;
    this.dexterityToken = dexterityToken;

    this.pawnshop = pawnshop;
    this.pawnshopRouterFactory = pawnshopRouterFactory;
    this.networkToken = networkToken;
    this.sponsoredHero = sponsoredHero;
    this.relay = relay;
    this.dataStorage = dataStorage;
    this.userController = userController;
    this.itemControllerHelper = itemControllerHelper;
    this.guildController = guildController;
    this.shelterController = shelterController;
    this.shelterAuctionController = shelterAuctionController;
    this.rewardsPool = rewardsPool;
    this.pvpController = pvpController;
  }
}
