import { CoreAddressesModel } from '@models/core-addresses.model';
import { PoolAddressesModel } from '@models/pool-addresses.model';
import { TokenInfo } from '@shared/constants/addresses/addresses.constant';

export const FANTOM_CORE = new CoreAddressesModel(
  '0xE5365c31c08d6ee44fdd33394ba279b85557c449', // controller
  '0xe4436821E403e78a6Dd62f7a9F5611f97a18f44C', // gameToken
  '0x7C01992Cd34Cd81d48b41E878Bd1765351e2A4eb', // minter
  '0xDdA2b04b32BF1EAe68A84e13521E29F24172f18c', // goc
  '0xA2D15afbD6C2018BF2909577Af9f134d3dEbE74e', // reinforcementController
  '0x89e8FA64D576d84E0143Af9ee9c94f759F1eF759', // dungeonFactory
  '0xBe46D95DB685aB3A544D321E196375B737ea6Bc4', // heroController
  '0x0Dfd9B9b25cEF61033CA680526722c8335281b8C', // itemController
  '0x84a339DDeEdC2637a14145495EDE5c55e38f1ec6', // oracle
  '0xFC2b899424B11db8d3899498989a6ff7cEAa5885', // statController
  '0x6C30D3B8739f30a9CD39cB3D5E0aa7a662f126Ef', // storyController
  '0x146dd6E8f9076dfEE7bE0b115bb165d62874d110', // treasury
  '0x04FeaD4a7444762315B7F488353ba1E3B6dFB86b', // statReader
  '0xE6C9e172d767F9DE38Bfd391F396A02146eBe637', // multicall
  '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // magicToken (WFTM)
  '0xe4436821E403e78a6Dd62f7a9F5611f97a18f44C', // strengthToken
  '0xe4436821E403e78a6Dd62f7a9F5611f97a18f44C', // dexterityToken

  '0xfc0a5E585Ddc9bD07D5513848d1232b00F9e8264', // pawnsop
  '0xdB3eb5370d642CF68B38D78a3E202fe72D17ed17', // PawnShopRouterFactory
  '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // network token
  '0xc8940050A4ba18cf59f1a0b874a7d0b308F0dE16', // sponsored hero
  '0x52CEba41Da235Af367bFC0b0cCd3314cb901bB5F', // relay
  '0xC25283b82bA0f230604E37668E6289499057b7fd', // data storage
  '0xC9E470A0c99b6229E3Dd0a35787Ef95D9E13f6B3', // user controller
  '0x13b0f55717FA617FBb10eF9A3DD07F0B49117c57', // item controller helper
  '0x72dB722129A2694B7eD41E79F2C13aA23124849d', // guild controller
  '0x877ce39Cf1a9698aF5D23bdc52c9Cb7Ec4D112c4', // shelterController
  '0x66C42049673055d28d51D568084eA4A4D1E281B1', // shelterAuctionController
  '0x8E629C4301871d2A07f76366FE421e86855DC690', // rewardsPool
  '0x0000000000000000000000000000000000000000'  // pvp controller
);

export const FANTOM_TOKEN_INFO = new Map<string, TokenInfo>([
  [FANTOM_CORE.gameToken.toLowerCase(), { symbol: 'SACRA', decimals: 18 }],
  [FANTOM_CORE.networkToken.toLowerCase(), { symbol: 'WFTM', decimals: 18 }],
  ['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', { symbol: 'FTM', decimals: 18 }],
]);

export const FANTOM_POOLS = new PoolAddressesModel(
  '0x56e837286DC7366eF6d6464D332AC6F9D32bC5A0', // ethSacraPool
);

export const FANTOM_DEX = {
  QUOTER_V2: '0xB9507f2ED171D52c5c2EFaeAbdE440d264504A92',
  SWAP_ROUTER_V2: '0x40F70B72796C30f355dF859B2c8F94f18c38AdF8',
  NATIVE_USDC_POOL: '0x406c1d0b777D6F98B908fc9E20AFAe32f3C30bD9',
};

export const FANTOM_ITEMS = {
  'SACRA_CONS_1_ITEM': '0x65bE4F5e6dC20B008593437E6aA8238A79F06646',
  'SACRA_CONS_2_ITEM': '0xbDE91d7aC25379BEB975b063c6D31A04d3bA9B82',
  'SACRA_CONS_3_ITEM': '0x48aD115540a0f9E128Ee62dF5Ce519451ba5F6E0',
  'SACRA_CONS_4_ITEM': '0xf6c0CEC1dd23122Da3B2e1f377E11F6c4deA13F5',
  'SACRA_CONS_17_ITEM': '0x9f4220B5288C069Da23804a07a3fa0913F9162A6',
  'SACRA_CONS_18_ITEM': '0x2F1cb44ba1cE5adD6751656f4a324E5Fa95c2d9b',
  'SACRA_CONS_19_ITEM': '0xA02d64d7A7b46448881529a6AB83b9bb62F6F52f',
  'SACRA_CONS_20_ITEM': '0x686D674B50C638b5a1C6219087F234150c57B014',
  'SACRA_CONS_21_ITEM': '0xfFDBa6cf325F026a22435FE2C34EFD54Dcdb2e6f',
  'SACRA_CONS_27_ITEM': '0x11A82F0F6602b4EbEbFD829Ab1e325f75D365A08',
  'SACRA_CONS_28_ITEM': '0x0151f7EC323e229CB6C8d990EF2108824183FeDc',
  'SACRA_CONS_29_ITEM': '0x9193bc4c50aB77307a09aDc78a438163A27F825E',
  'SACRA_CONS_30_ITEM': '0xf3F1bbA0CefB367105Ed74e8234cF3522fe8C89E',
  'SACRA_CONS_31_ITEM': '0x537BaA15145b3AA17C19668fB0c9080ABCB463c6',
  'SACRA_CONS_32_ITEM': '0xbe947861f9E9cfe4acf34C1b80d5e24f00d7D4a6',
  'SACRA_CONS_35_ITEM': '0xe77308Bf604c630eF5A81DFE9d1cb54482923beE',
  'SACRA_OTHER_1_ITEM': '0x30511370Ce65e8806784d42068e3fF7A7d82F3Ad',
  'SACRA_OTHER_2_ITEM': '0x3a283a159E3068E3e53fc053E64d4479FC75C04F',
  'SACRA_OTHER_3_ITEM': '0x93858c324A7FB93dc7a01e7c7A9f344E74BD67d3',
  'SACRA_OTHER_4_ITEM': '0x424E8B81C6a2f3B35A07f14936c78aFa55609a2D',
  'SACRA_OTHER_5_ITEM': '0x14A722BFD8E452005CeEd3AABc3482970a728a5b',
  'SACRA_HELM_1_ITEM': '0x7d7b946f841509763d391f0c5B58941C5a95b8C7',
  'SACRA_HELM_6_ITEM': '0x24DcC28defE180cC0287df184E41FE9e067eCBf4',
  'SACRA_HELM_9_ITEM': '0x3143aA36FBFB46c265Ef3181c257f8F8fbE80923',
  'SACRA_BODY_1_ITEM': '0x631D8C22663CFb2661b4cC025f81786096B9a7d2',
  'SACRA_GLOVES_1_ITEM': '0xd8288634480414740Eaf387C412d211432543f36',
  'SACRA_GLOVES_6_ITEM': '0x26a3f2be8725B26255D0D591ba8162a17c1C80A7',
  'SACRA_BELT_1_ITEM': '0xa2CC2cA0AdEA6b4F5ffad7c9181379c2ecC9B626',
  'SACRA_BELT_5_ITEM': '0xC93156CBA85C80D48E3A5fbB20a40277B0da512b',
  'SACRA_AMULET_1_ITEM': '0xD45347527c567244CfDca6c296D4F0940F747D98',
  'SACRA_AMULET_2_ITEM': '0xfA9D84A7aC9e2dC4DD43D566673B6C018E601b44',
  'SACRA_AMULET_12_ITEM': '0x00dFAAec6b9BcF4Da6D48B6019E6260917C20218',
  'SACRA_AMULET_13_ITEM': '0xb8a87473D248D75d596c0502AfAa6509f50d9916',
  'SACRA_RING_1_ITEM': '0x2c3Ef54Fc671cC9aBc172e3058FD89CE0387bbde',
  'SACRA_RING_2_ITEM': '0xA39C5DAc33F36abCCed1D5ac02836136cBa2375C',
  'SACRA_RING_9_ITEM': '0x1C5d1D935e38de9890630FfC0bc8C7fFF322E7d7',
  'SACRA_OFF_1_ITEM': '0xC3B72301f1CFebfFC3b5A8e853E6327269A0D4FE',
  'SACRA_OFF_2_ITEM': '0x5d3cfAF7f22F39fC0150bE1Cf6a37EBB241539DF',
  'SACRA_OFF_10_ITEM': '0x57f3BCD308A38C5677112339193FEfe82220B433',
  'SACRA_BOOTS_1_ITEM': '0x577A94Eb646152bd18beFa556b2104FA4361eBB9',
  'SACRA_BOOTS_5_ITEM': '0xcDff960A8e893F77a42317fE7f78e54b7CeF714B',
  'SACRA_OHW_1_ITEM': '0x0dE3ab43fc003ad7924BF8e5b2aDD6BA1f524bdD',
  'SACRA_OHW_2_ITEM': '0xe30F0B192E6c725690046Aa7A6d13f53b699a292',
  'SACRA_OHW_3_ITEM': '0x6eEb3F66D945A7ca97E8e0ce2C876F8Ed6cc8D7F',
  'SACRA_THW_1_ITEM': '0x687c3864e6fb6783c24C22c57160F4585eDCF810',
  'SACRA_THW_2_ITEM': '0x21871160eaF6355197C1D6413Cb99f42946C9c53',
  'SACRA_FEAR_ITEM': '0x20c50b531F1833F8C19f0035bFdE1c1195B20741',
  'SACRA_DEMON_SPEED_ITEM': '0xFB1bFDDD34859B19f4F287d464AC68F807DDB3ED',
  'SACRA_BRUTE_FORCE_ITEM': '0x6324392bF0e9a6519663dcc00cb9133CFE14aa38',
  'SACRA_SAVAGE_CHARM_ITEM': '0x8cE719075aCacdcd29a02263A3aA6936D18DE9b9',
  'SACRA_GOLDEN_EYE_ITEM': '0xFEeB1678d34B5129569E7BAf8881DeDA42865C16',
  'SACRA_SABBAH_POISON_ITEM': '0x2E6115353088f5C3DC1691107C2306690dF3fcef',
  'SACRA_SPIT_IN_FACE_ITEM': '0xFbEa8e1698724A0977b7C511f99FAceBC8c2Cc30',
  'SACRA_ONSLAUGHT_ITEM': '0xAf149EBf9A9b3Ee4206CE7A8D780d6C187432fb9',
  'SACRA_STRONG_BLOCK_ITEM': '0x022160909364D703c1BC26877668a987a30fE582',
  'SACRA_BEEF_PATTY_ITEM': '0xD1F3a02BB2E77D787fcE4608D9C39d8B9D0E9fD6',
  'SACRA_HEAT_STRIKE_ITEM': '0x56F318b9c7D269311b9E791289778Da8866C1346',
  'SACRA_STATIC_DISCHARGE_ITEM': '0x867ba27Ddb0b34CC0700294BD868AE4B6B47f951',
  'SACRA_CONS_5_ITEM': '0x32e9F3C421abeF0812231E1CBcE514e203199B4a',
  'SACRA_CONS_6_ITEM': '0x06e3EE77E225c2437Cb9A057e134b0C1F6Ae6bdC',
  'SACRA_CONS_7_ITEM': '0xB509d72daD8d9EBE76D660Ed49FeA2E46dcF9673',
  'SACRA_CONS_8_ITEM': '0x27268523620Cfa0960e163a0a14b40068C18B0fa',
  'SACRA_CONS_22_ITEM': '0xe04610B1Cf4263aE2252aCA07828c4eD74b150bD',
  'SACRA_HELM_2_ITEM': '0x0400aD8622877103D9995D7f8022486EdfAEC6Ac',
  'SACRA_HELM_7_ITEM': '0x8049E670fd8d6ffFB6BDCF812f924025D818Cf1D',
  'SACRA_BODY_2_ITEM': '0x79cca8E6E3193537c1A81Be94Ad6af84d789964e',
  'SACRA_BODY_6_ITEM': '0x7a3F3266b8cd535358039FE6aB3e7B39fC9Bb363',
  'SACRA_BODY_8_ITEM': '0x48Ff77F37cE38e9d72AE8E119826592715669375',
  'SACRA_GLOVES_2_ITEM': '0x5e351E1084c7a5ca680D5d091cD52B7567F8f2c0',
  'SACRA_GLOVES_7_ITEM': '0xEd22007F9C5c9cB7C8F4b38F782396145ba0107B',
  'SACRA_BELT_2_ITEM': '0xd4d3cB65718580E48f4cDCefB0170e25D31Acb44',
  'SACRA_AMULET_3_ITEM': '0x8031Ff91BfE333393Bf54c535393e4CcFfBC2929',
  'SACRA_AMULET_4_ITEM': '0x50D3889A5E43440Af720d554F81ED4f89B9B66c1',
  'SACRA_AMULET_9_ITEM': '0x6fb3dB26cc713b7BA0113942D4bb15D90490c1dB',
  'SACRA_RING_3_ITEM': '0x1F6EF9B8A37F9BD9291556F56207C2E4B2290008',
  'SACRA_RING_4_ITEM': '0x5ea30673AF0eb6220fAeF6314714EbfaBE51E0D5',
  'SACRA_RING_10_ITEM': '0xA5267BCBFe6D45CcD99F44c7EeeBFDC31cF7468a',
  'SACRA_RING_11_ITEM': '0x96939Fba211f947144Ff34cC3FB428283cAf29C9',
  'SACRA_OFF_3_ITEM': '0x9BcB0Aefa38a5E896d12a1923CC68b88eB029576',
  'SACRA_OFF_4_ITEM': '0x6e1e630C97858Da6cB0D91953990dF4004012FE7',
  'SACRA_OFF_11_ITEM': '0xcd6eAdcB1f6038143DBF952277d41392d1c96E04',
  'SACRA_BOOTS_2_ITEM': '0x246bDD7567644f57D6640980F3dE960873128750',
  'SACRA_OHW_4_ITEM': '0x0ddbEEf7393F94C85Fd71bCEb2B3F304a58F0B66',
  'SACRA_OHW_5_ITEM': '0x596070274CB2AE44a973397bdA5ad3E0456e8E0C',
  'SACRA_OHW_6_ITEM': '0xcE2eF428F84584D629b767B328b76e823Ddb2C17',
  'SACRA_THW_3_ITEM': '0x5eEbcf27A20318D217440d30916c4BCa9d796CC0',
  'SACRA_THW_4_ITEM': '0x377eCEb42265AAAF9E34a10B5C6e3789A884e1fC',
  'SACRA_THW_5_ITEM': '0x3b144755b615106E69F8ce53aB38a057DbED31Cd',
  'SACRA_STEEL_SKIN_ITEM': '0x61b0660404e76465844059F3B6b2eDBBFaB5e79b',
  'SACRA_LIFEDRAIN_ITEM': '0x323b727B4fFa604ECCFf0eb08f021f2a50c6A231',
  'SACRA_FROSTBITE_ITEM': '0x26A6F04B8608a747bC17b73Ce3a231E440f73154',
  'SACRA_LIKE_A_PUSSY_ITEM': '0x9bEFEE98F8BD202AC84Af2653ae7F02Edbd843b9',
  'SACRA_RAGE_ITEM': '0xeE1a16308833Ae2f883A7485e1577b7943ca7336',
  'SACRA_DEAD_STRIKE_ITEM': '0x1FDEf0c8cBCf93C3E6acb6Fe079F895d5A981c7c',
  'SACRA_FIRM_HAND_ITEM': '0x11aE6E2F77186C9CCb9e1C9EdcE32CCfdC9e79bC',
  'SACRA_JUSTICE_ITEM': '0x32a94189F241025D650d5C4F4d6707cA90337Aab',
  'SACRA_STEP_BACK_ITEM': '0x1A35e16E5f61159AFf6Cd3fB9223E52c7630dBb1',
  'SACRA_PATIENCE_ITEM': '0x9F25727C769EA0A80430B3012b7A3dc15FF3EDE8',
  'SACRA_TIME_AND_EXPERIENCE_ITEM': '0xd04D97DB3c89fE2Ed5f3D28667db8b266BABdD4e',
  'SACRA_MELT_ITEM': '0xD731ABc4F089533b7BA1E680403DEBae8084A3Cf',
  'SACRA_ICE_SHARD_ITEM': '0xf82c61cE10172Dc1177c9599EDDc4c4a6C713Fa1',
  'SACRA_CONS_9_ITEM': '0x5B9FFf1f20f354C634C4b760e8dbd7dDD13e4e42',
  'SACRA_CONS_10_ITEM': '0xc4C39eb47086c54ce6944b3Cd5c4798F18B73929',
  'SACRA_CONS_11_ITEM': '0x6a8649fdC0C05033c200E667B48FF3B3aa47888e',
  'SACRA_CONS_12_ITEM': '0x680d6794bD5757D050CA1b036514163202A6A660',
  'SACRA_CONS_23_ITEM': '0x805c93D3e99b3e4b391d54f9De5902b34F213206',
  'SACRA_CONS_24_ITEM': '0x10FBb7201D06315D1B5a40F8905ea5E2539835c9',
  'SACRA_HELM_3_ITEM': '0x1De9c63A1aD6C015168AaC10F4Eb16C09468f514',
  'SACRA_HELM_8_ITEM': '0x6785dC177171E83f2a52eC97CD81473C177721F5',
  'SACRA_BODY_3_ITEM': '0x4e1b19D11E3C14259D0507657897C5A211006F64',
  'SACRA_BODY_7_ITEM': '0x1DafF33E5A98acA6aA61688d9388cd883ce6c803',
  'SACRA_GLOVES_3_ITEM': '0xAd2A8D5C49B35aA579C9C1e11d0f35af42d08372',
  'SACRA_GLOVES_8_ITEM': '0xfED1f6C196e82042637cc51e0f1f0BBfC3800674',
  'SACRA_BELT_3_ITEM': '0x39433bf88d37722E9eDC120ef716c358EC0Cb0F8',
  'SACRA_AMULET_5_ITEM': '0x51dd8B2Ba625BEadc49505dD30eC380c58afB08D',
  'SACRA_AMULET_6_ITEM': '0x440F66314da8dAC668Aaf65aebD16B3Ac4d71746',
  'SACRA_AMULET_10_ITEM': '0xA9227587A70A1e741027c8352085fE2157870F80',
  'SACRA_AMULET_11_ITEM': '0x42F72A72A24d52983906cB623d5944a6cC6357E9',
  'SACRA_RING_5_ITEM': '0xA7572210789fDAcB32e22B7Bb9904B44108bf593',
  'SACRA_RING_6_ITEM': '0xC3191247B9cAec11329583e21A447DD937362106',
  'SACRA_OFF_5_ITEM': '0x046224bB80981519eBD144b0433782f2ec933850',
  'SACRA_OFF_6_ITEM': '0xE399fC6df5F07483D8D84aDF0dfc3Dc081048419',
  'SACRA_BOOTS_3_ITEM': '0x815BEB5E463b8D34Ce35300Fe06C91A950533F56',
  'SACRA_BOOTS_6_ITEM': '0xd0c3CEc76C0834E0D95e7b3da34480d9579961BC',
  'SACRA_OHW_7_ITEM': '0x9d6304a90662D7883b68CF7c2eB81E748A20171a',
  'SACRA_OHW_8_ITEM': '0x94D19d186DAC74268E49316Fb48d0b2546a39779',
  'SACRA_OHW_9_ITEM': '0xA5E288d50612459acCadA62d48210a6d0A7c87cc',
  'SACRA_OHW_12_ITEM': '0xd27760dE6d33102Ca54cdB571100109004651579',
  'SACRA_OHW_13_ITEM': '0x121d1D47aC63fAF123b29E3267fa8feb1fADc65c',
  'SACRA_OHW_14_ITEM': '0xF54c10B5f56B154e25FBfd35BE61580CC93D5F6F',
  'SACRA_THW_6_ITEM': '0x7c839a7E3604080a18dAbba949b0e9808aa59907',
  'SACRA_THW_7_ITEM': '0xE277A376a5d8FDA4f075ec3f8Aab94973700Db6A',
  'SACRA_THW_10_ITEM': '0xaBb4B23C262FC584d069D3af7E2620CbC30d9059',
  'SACRA_PARALYZE_ITEM': '0x028E350fBDE6f8C4Fd8c719416B25648e30be2bf',
  'SACRA_GUIDING_LIGHT_ITEM': '0x3F1988c9c0801383dabee5D2c8d1D6CeE8b1c16E',
  'SACRA_GUT_PUNCH_ITEM': '0xE949C2d65304E2753651adE322B7CEC59163d357',
  'SACRA_LAST_DANCE_ITEM': '0x8320E8128d4abe2Ee305Ca01cE6abC2A72aff12C',
  'SACRA_I_SURVIVE_ITEM': '0xD4BFC7EE2335a0412fBF46E0C73536389313B7c5',
  'SACRA_ARMOR_OF_FAITH_ITEM': '0xFFDF53b19D7d717347c86096Ac2bd1Dba1DA84b5',
  'SACRA_THUNDERBOLT_ITEM': '0x8dCDa07Fb2d5273Bd079b36174F2863e37981E74',
  'SACRA_CONS_13_ITEM': '0xc6516B2f3a31234afed6a49FD15Bc6332da924d1',
  'SACRA_CONS_14_ITEM': '0xa915Fa830deB0fa3bc7B088055e5339e7c84f87C',
  'SACRA_CONS_15_ITEM': '0xfd6628cCa01DD35d7aD02B651dAEc24c1a72EAA3',
  'SACRA_CONS_16_ITEM': '0xa1003D99Ad1260F7038b3663b50619C4c145d58E',
  'SACRA_CONS_25_ITEM': '0x414558CFB0170AFc69Aa667666ab8996B847363C',
  'SACRA_CONS_26_ITEM': '0x1eDeE3C5a54d3A9c1d3d42cc70fa10bd855bC34E',
  'SACRA_HELM_4_ITEM': '0x13112589b977B05984483627BE28F742EA8b89e5',
  'SACRA_HELM_5_ITEM': '0x34db68179A41F154e67F3187e17584901d7DAA55',
  'SACRA_BODY_4_ITEM': '0x816EbCF4f7d9BEc37df3Bc0ab652c95227ff9bEC',
  'SACRA_BODY_5_ITEM': '0x094234D8A9E16150006B5f129BBd8Fc889a050F0',
  'SACRA_GLOVES_4_ITEM': '0x2Eb80B6c4ec31fe90eD1654Cbf78c77823f61aBB',
  'SACRA_GLOVES_5_ITEM': '0xFCF678F336443055deD0ABf7A4c989f4Ee9aCFB1',
  'SACRA_BELT_4_ITEM': '0xa6C013B2413c37f9e7f184D9410c8B59ad9D26f4',
  'SACRA_AMULET_7_ITEM': '0xCc6D9b0506f0929Ced13f1143Dab2d4fF59deaCC',
  'SACRA_AMULET_8_ITEM': '0x63D600f4A9aA256a536FedD5B6E476dDFd2a2604',
  'SACRA_RING_7_ITEM': '0xeD0018A4DA09708A1d4E6e37362600107dA5C83b',
  'SACRA_RING_8_ITEM': '0x41645Fd87199203ED9a467f3Cf27F78B97AF784a',
  'SACRA_OFF_7_ITEM': '0xd649B6E628f243bEbcF57AF396d4cA3e5CBDb333',
  'SACRA_OFF_8_ITEM': '0xC5aEc6aC152F041D0BDC34b41c93bAe228090f4B',
  'SACRA_OFF_9_ITEM': '0x60feF6f739e809B4e960692293AB2A68cb0B14F5',
  'SACRA_OFF_12_ITEM': '0x8a216F4E21E3D3173E1Ce179261110FEcdb48A97',
  'SACRA_OFF_13_ITEM': '0xFC09e2d57f47320Fd69F1B48CBa6174E069baa65',
  'SACRA_BOOTS_4_ITEM': '0x5b6e1ca2754183D9ED4375527AaC00862470D2E5',
  'SACRA_BOOTS_7_ITEM': '0x521d3aaDF569F2C0ec69dfDFD62917b34Ace76e3',
  'SACRA_OHW_10_ITEM': '0x54F088015d801123908a6a6f599cfd3fEC245228',
  'SACRA_OHW_11_ITEM': '0x210757ED4Ea04EADafa6016fdb0E9b0248bf0944',
  'SACRA_THW_8_ITEM': '0xeaa7Aa2b8D3Bfc9e0a06bd677e5B101ed87cB895',
  'SACRA_THW_9_ITEM': '0x27960e12bD32Fe6d512EadBdA9E8Aff9E46C64d2',
  'SACRA_THW_11_ITEM': '0xE2ADB0227a6EAa6BF2985e3919C4C288A162F2ba',
  'SACRA_REFLECTION_ITEM': '0x5b9409DF2a6E1B3D1d4ca35CfFe60B65cE6139B3',
  'SACRA_SCARLET_BRANCH_ADEPT_ITEM': '0xA5B8e0846AA69E612373f4c05914d8Aa5C7c6c6f',
  'SACRA_ANATHEMA_ITEM': '0x4ff0CC9a65C3EC8057d9e4e414feBBD86d79B4bB',
  'SACRA_GIANTS_BLOOD_ITEM': '0x65F7614ea637103Bd71a66A6ac22e34a95c6D682',
  'SACRA_BACKSTAB_ITEM': '0x5D2A0b663Db1F779c7010A03b55993e3Cacc7531',
  'SACRA_VETERAN_CONFIDENCE_ITEM': '0x5bE9FC89d22180AEF5ADD463c623D01e37DC983d',
  'SACRA_LIVE_FORTRESS_ITEM': '0xD0c15C63eAfE9A306bA675D1e177606636f6359b',
  'SACRA_COLD_SHOULDER_ITEM': '0x5DDd526D8812d71ab0D88145E626559EB2e6A632',
  'SACRA_ERASE_ITEM': '0x345D00cC29E34E9Dd472cEDA1F33174A690Bf4Ab',
};
