<div class="slider">
  <img
    class="slider__bg slider__bg--long"
    [class.slider__bg--medium]="size === 'medium'"
    [class.slider__bg--medium-long]="size === 'medium-long'"
    [src]="
      'assets/images/ui/elements/slider/' +
      (size === 'default' || size === 'medium' || size === 'medium-long' ? 'slider_long' : 'slider_short') +
      '.png'
    "
    alt="" />

  <div
    class="slider__point"
    [class.slider__point--disabled]="isDisabled"
    [draggable]="!isDisabled"
    (dragstart)="dragStart($event)"
    (touchstart)="dragStart($event)"
    (dragend)="dragEnd()"
    (touchend)="dragEnd()"
    (drag)="drag($event)"
    (touchmove)="drag($event)"
    [ngStyle]="translate">
    <img
      class="slider__point-image"
      [src]="'assets/images/ui/elements/slider/slider_point' + (isDisabled ? '_disabled' : '') + '.png'"
      alt="" />

    <div
      class="slider__percentage app-btn-medium-small_font-size g-flex g-flex--align-center text-center"
      [class.slider__percentage--disabled]="isDisabled"
      [class.slider__percentage--centered]="labels.length > 0">
      @if (isShowTooltip) {
        <div
          class="slider__percentage-desc"
          [class.slider__percentage-desc--left-10]="value < maxValue / 10"
          [class.slider__percentage-desc--left]="value >= maxValue / 10 && value < maxValue / 2"
          [class.slider__percentage-desc--right]="value >= maxValue / 2"
          [class.slider__percentage-desc--right-100]="value === maxValue"
          [class.slider__percentage-desc--visible]="isPercentageVisible">
          {{ description }}
        </div>
      }

      <div class="slider__percentage-value">{{ labels.length ? labels[value] : value }}{{ postfix }}</div>
    </div>
  </div>
</div>
