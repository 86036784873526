import { ItemEntity } from '@generated/gql';
import { isMobile } from '@services/web3modal-ts/src';
import { ethers } from 'ethers';

// export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isAndroid = /Android/i.test(navigator.userAgent);
export const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const isFirefox = /firefox/i.test(navigator.userAgent);

const hasEthereum = !!window?.ethereum;
// A mobile browser with ethereum we assume it's Metamask Browser
export const isMetamask = isMobile() && hasEthereum;

export function getValueByKey<T>(model: any, key: string): any {
  if (key.indexOf(':') > 0) {
    const delimiterIndex = key.indexOf(':');
    const id1 = key.substring(0, delimiterIndex);
    const id2 = key.substring(delimiterIndex + 1);

    return `${model[id1 as keyof T]}-${model[id2 as keyof T]}`;
  }

  return model[key as keyof T];
}

/**
 * return array contains increasing sequence of natural numbers with step 1 started from `from` up to excluded `to` values.
 * Example rangeArray(5, 8) => [5, 6, 7]
 *
 * @param from - start index in the range included
 * @param to - end index in the range excluded
 */
export function rangeArray(from: number, to: number): number[] {
  return Array.from({ length: to - from }, (_, id) => id + from);
}

export function adjustGasLimit(gas: bigint, min = 2_000_000n) {
  gas = (gas * 150n) / 100n;
  if (gas > 6_900_000n) {
    console.warn('gas assumption to high', gas);
  }
  if (gas < min) {
    return min;
  }
  return gas;
}

export function valuesOfEnum(enumClass: { [key: number | string]: number | string }): number[] {
  return Object.values(enumClass).filter((x: number | string) => {
    return typeof x === 'number' && x !== 0;
  }) as number[];
}

export function keysOfEnum(enumClass: { [key: number | string]: number | string }): string[] {
  const zeroElementName = enumClass[0];
  return Object.values(enumClass).filter((x: number | string) => {
    return typeof x === 'string' && x !== zeroElementName;
  }) as string[];
}

export function toByte32(str: string) {
  const b = ethers.toUtf8Bytes(str).slice(0, 32);
  return ethers.zeroPadBytes(b, 32).toLowerCase();
}

export const onlyDigitsRegExp = new RegExp(/[^\d.-]+/g);
export const onlyDigitsDecimalRexExp = new RegExp(/[^\d.-]+/g, 'g');
export const onlyEnglishLettersRegExp = new RegExp(/^[a-z0-9\s]*$/i);
export const onlyASCIIRegExp = new RegExp(/^[\x00-\x7F]*$/i);
export const strongPasswordRegExp: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
export const onlyLatinLettersDigitsSpec = /^[a-zA-Z\d\s!@#$%^&*;:}{|~`'"><)(+=.,_-]+$/;

export function getOnlyNumbersString(string: string, hasDecimal = false): string {
  const replacePattern = hasDecimal ? onlyDigitsRegExp : onlyDigitsDecimalRexExp;
  return string.replace(replacePattern, '');
}

export function getOnlyEnglishLettersString(string: string): string {
  return string.replace(onlyEnglishLettersRegExp, '');
}

export function genObjectId(biome: number, subType: number, id: number) {
  return biome * 1_000_000 + subType * 10_000 + id;
}

export function countWordsInTranslate(obj: object) {
  let str = '';
  Object.values(obj).forEach(it => {
    Object.values(it).forEach(v => {
      if (typeof v === 'object') {
        str += Object.values(v as object);
      } else {
        str += v;
      }
    });
  });

  str = str
    .replace(/<br>/g, ' ')
    .replace(/<br\/>/g, ' ')
    .replace(/&/g, ' ')
    .replace(/$/g, ' ')
    .replace(/{/g, ' ')
    .replace(/}/g, ' ')
    .replace(/<i>/g, ' ')
    .replace(/<\/i>/g, ' ')
    .replace(/\\/g, ' ')
    .replace(/"/g, ' ')
    .replace(/“/g, ' ')
    .replace(/”/g, ' ')
    .replace(/\n/g, ' ')
    .replace(/\r/g, ' ')
    .replace(/\./g, ' ')
    .replace(/,/g, ' ')
    .replace(/;/g, ' ')
    .replace(/:/g, ' ')
    .replace(/!/g, ' ')
    .replace(/-/g, ' ')
    .replace(/\(/g, ' ')
    .replace(/\)/g, ' ')
    .replace(/\[/g, ' ')
    .replace(/\]/g, ' ')
    .replace(/ +(?= )/g, '');

  const wordsArr = str.split(' ');

  console.log('words count', wordsArr.length);
  console.log('words array', wordsArr);
}

export function countItems(items: ItemEntity[], onlyConsumableItems = true) {
  const obj = {};

  items
    .filter(it => !onlyConsumableItems || (onlyConsumableItems && it.meta.isConsumableItem))
    .forEach(it => {
      if (!obj[it.meta.name]) {
        obj[it.meta.name] = 0;
      }

      obj[it.meta.name] = obj[it.meta.name] + 1;
    });

  return obj;
}

export function countItemsById(items: ItemEntity[]) {
  const obj = {};

  items.forEach(it => {
    if (!obj[it.meta.id]) {
      obj[it.meta.id] = 0;
    }

    obj[it.meta.id] = obj[it.meta.id] + 1;
  });

  return obj;
}

export function toBytes(str: string): string {
  const buf = Buffer.from(JSON.stringify(str), 'utf-8');
  return '0x' + buf.toString('hex');
}

export function fromBytes(str: string): string | null {
  if (str.length < 3) {
    return null;
  }
  const bufDecrypted = Buffer.from(str.substring(2), 'hex');
  return JSON.parse(bufDecrypted.toString('utf-8'));
}

export const resolvePath = (object, path) => {
  return path.split('.').reduce((o, p) => (o ? o[p] : null), object);
};

export const CLICKABLE_ELEMENTS = [
  'APP-DROPDOWN',
  'APP-SEARCH',
  'APP-CHECKBOX',
  'APP-RADIO-GROUP',
  'BUTTON',
  'APP-ITEM-CARD',
  'APP-HEADER',
  'APP-ITEM-SLOT',
];

export const checkClickableNode = (element: HTMLElement) => {
  if (CLICKABLE_ELEMENTS.includes(element.nodeName)) {
    return true;
  }

  if (!element.parentElement) {
    return false;
  }

  if (CLICKABLE_ELEMENTS.includes(element.parentElement.nodeName)) {
    return true;
  }

  return checkClickableNode(element.parentElement);
};

export function getMaxMembersSizeByGuildLevel(level: number) {
  return 10 + (level - 1) * 2;
}

export function unpackShelterId(shelterId: number): { biome: number; shelterLevel: number; shelterIndex: number } {
  return {
    biome: shelterId & 0xff,
    shelterLevel: (shelterId >> 8) & 0xff,
    shelterIndex: (shelterId >> 16) & 0xff,
  };
}

export function getPenaltyPercent(dominationCounter: number): number {
  if (dominationCounter !== 0) {
    if (dominationCounter === 1) return 10;
    else if (dominationCounter === 2) return 25;
    else if (dominationCounter === 3) return 38;
    else if (dominationCounter === 4) return 50;
    else if (dominationCounter === 5) return 61;
    else if (dominationCounter === 6) return 70;
    else if (dominationCounter === 7) return 78;
    else if (dominationCounter === 8) return 84;
    else if (dominationCounter === 9) return 89;
    else if (dominationCounter === 10) return 93;
    else if (dominationCounter === 11) return 96;
    else return 98;
  }
  return 0;
}